import React, { Component } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import PropTypes from 'prop-types';
import { MIN_DELIVERY_ORDER } from './constants';
import banner from 'shared/img/dellivery-min.png';

import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

class Delivery extends Component {
  componentDidMount() {
    const { getSettings, userToken, settings } = this.props;

    if (_.isEmpty(settings)){
      getSettings(userToken);
    }
  }

  render() {
    const { style } = this.props;


    return(
      <div className={style.root}>
        <Breadcrumbs 
          breadcrumbs={[<FormattedMessage id="Delivery.TITLE" />]} 
        />
        <div className={style.container}>
          <div className={style.banner}>
            <img
              src={banner}
              alt="Delivery Banner"
              className={style.bannerImage}
            />
            <div className={style.bannerContent}>
              <div className={style.bannerList}>
                <div className={style.bannerItem}>
                  <h2>{`$${MIN_DELIVERY_ORDER}`}</h2>
                  <p><FormattedMessage id="Delivery.MIN_ORDER" /></p>
                </div>
                <div className={style.bannerItem}>
                  <h2><FormattedMessage id="Delivery.PAYMENT_TITLE" /></h2>
                  <p><FormattedMessage id="Delivery.PAYMENT_TEXT" /></p>
                </div>
                <div className={style.bannerItem}>
                  <h2><FormattedMessage id="Delivery.SHIPPING_TITLE" /></h2>
                  <p><FormattedMessage id="Delivery.SHIPPING_TEXT" /></p>
                </div>
              </div>
              <h1 className={style.freeShipping}>
              <FormattedMessage
                id="Delivery.FREE_SHIPPING"
                values={{ free: <span className={style.freeShippingThin}><FormattedMessage id="Delivery.FREE" /></span> }}
              />
              </h1>
              <p className={style.discountText}>
                <FormattedMessage
                  id="Delivery.INDIVIDUAL_DISCOUNTS"
                  values={{ text: <span><FormattedMessage id="Delivery.INDIVIDUAL_DISCOUNT_TEXT" /></span>, br: <br /> }}
                />
              </p>
            </div>
          </div>
          <div className={style.bottomItems}>
            <div className={style.bottomItem}>
              <div className={style.bottomHeading}>
                <FormattedMessage id="Delivery.BOTTOM_1_TITLE" />
              </div>
              <div className={style.bottomText}>
                <p><FormattedMessage id="Delivery.BOTTOM_1_TEXT_1" /></p>
                <p><FormattedMessage id="Delivery.BOTTOM_1_TEXT_2" /></p>
                <p><FormattedMessage id="Delivery.BOTTOM_1_TEXT_3" /></p>
              </div>
            </div>
            <div className={style.bottomItem}>
              <div className={style.bottomHeading}>
                <FormattedMessage id="Delivery.BOTTOM_2_TITLE" />
              </div>
              <div className={style.bottomText}>
                <p><FormattedMessage id="Delivery.BOTTOM_2_TEXT_1" /></p>
                <p><FormattedMessage id="Delivery.BOTTOM_2_TEXT_2" /></p>
                <p><FormattedMessage id="Delivery.BOTTOM_2_TEXT_3" /></p>
              </div>
            </div>
            <div className={style.bottomItem}>
              <div className={style.bottomHeading}>
                <FormattedMessage id="Delivery.BOTTOM_3_TITLE" />
              </div>
              <div className={style.bottomText}>
                <p><FormattedMessage id="Delivery.BOTTOM_3_TEXT_1" /></p>
                <p><FormattedMessage id="Delivery.BOTTOM_3_TEXT_2" /></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Delivery.defaultProps = {
  style: require('./Delivery.module.scss')
}

Delivery.propTypes = {
  getSettings: PropTypes.func.isRequired,
  userToken: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired
};

export default Delivery;