import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import fileDownload from 'js-file-download';
// import Switch from "react-switch";
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import { decOfNum } from 'utils/services';
import { apiFetchSupercatPrice } from './services';

import Modal from 'components/Modal';
import Loader from 'components/Loader';
import ProductCard from 'components/ProductCard';

import { ReactComponent as DownloadIcon } from 'shared/img/download.svg';

class Categories extends Component {
  constructor(props) {
    super(props);

    this.handleEscButton = this.handleEscButton.bind(this);
  }

  state = {
    search: '',
    editFavorite: false,
    activeNav: 0,
    downloadPriceModal: false,
    activeSuperCat: null,
    downloadFee: 0,
    downloadPriceProcess: false,
    lovedCategoriesChecked: false
  }

  componentDidMount() {
    const { categories, arrivals, fetchCategories, categoriesFetching, fetchArrivals, arrivalsFetching, userToken, arrivalsPerPage, incomesFetching, incomes, fetchIncomes } = this.props;

    !categoriesFetching && _.isEmpty(categories) && fetchCategories(userToken);
    !arrivalsFetching && _.isEmpty(arrivals) && fetchArrivals(userToken, arrivalsPerPage);
    // !favoritesFetching && _.isEmpty(favorites) && fetchFavorites(userToken);
    !incomesFetching && _.isEmpty(incomes) && fetchIncomes(userToken);

    document.addEventListener('keyup', this.handleEscButton);
  }

  componentDidUpdate(prevProps) {
    const { open } = this.props;

    if (prevProps.open && !open){
      this.setState({
        editFavorite: false
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleEscButton);
  }

  handleSearch = () => {
    const { search } = this.state;
    const { history, toggleCategories } = this.props;

    if (search.length){
      history.push('/search/'+search);
      toggleCategories();
    }
  }

  handleSearchKeyDown = e => {
    if (e.key === 'Enter') {
      this.handleSearch();
    }
  }

  handleSearchChange = e => {
    const { userToken, fetchCategory, clearSearchAutocomplete } = this.props;

    this.setState({search: e.target.value});

    if (e.target.value){
      fetchCategory(userToken, 'search', null, '?keyword='+e.target.value);
    }else{
      clearSearchAutocomplete();
    }
  }

  handleFavorite = () => {
    this.setState({
      editFavorite: !this.state.editFavorite
    });
  }

  handleEscButton(event) {
    if(event.key === "Escape" && this.props.open) {
      this.props.toggleCategories();
    }
  }

  handleRemove = catId => () => {
    const { controlFavorite, userToken } = this.props;

    const inFavorites = this.getInFavorites();

    controlFavorite(_.remove(inFavorites, favorite => favorite !== catId), userToken)
  }

  handleAdd = catId => () => {
    const { controlFavorite, userToken } = this.props;

    const inFavorites = this.getInFavorites();

    controlFavorite(_.uniq([...inFavorites, catId]), userToken);
  }

  handleCategoryNav = activeNav => () => {
    if (activeNav !== this.state.activeNav){
      this.setState({
        activeNav: activeNav
      });
    }
  }

  handlePriceModal = supercatId => () => {
    const { downloadPriceModal } = this.state;

    this.setState({
      downloadPriceModal: !downloadPriceModal,
      activeSuperCat: supercatId
    });
  }

  handleFeeInput = e => {
    this.setState({
      downloadFee: _.inRange(e.target.value, 100) ? e.target.value : ''
    });
  }

  handleDownloadPrice = () => {
    const { setNotification, userToken } = this.props;
    const { activeSuperCat, downloadFee } = this.state;

    this.setState({
      downloadPriceProcess: true
    });

    apiFetchSupercatPrice(activeSuperCat, downloadFee, userToken).then(response => {
      if (response.status === 200){
        fileDownload(response.data, `goods-${activeSuperCat}.xlsx`);
        this.setState({
          downloadPriceProcess: false
        });
      }else{
        setNotification('failApiFetchSupercatPrice');
      }
    }).catch(function (error) {
      setNotification('error');
    });
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.handleDownloadPrice();
    }
  }

  handleMoreArrivals = () => {
    const { fetchArrivals, userToken, arrivalsLimit, arrivalsPerPage } = this.props

    fetchArrivals(userToken, arrivalsLimit+arrivalsPerPage);
  }

  handleLoveChange = () => {
    const { userToken, fetchLovedCategories } = this.props;

    const lovedCategoriesChecked = !this.state.lovedCategoriesChecked;

    if (lovedCategoriesChecked){
      fetchLovedCategories(userToken);
    }

    this.setState({
      lovedCategoriesChecked: lovedCategoriesChecked,
      editFavorite: false
    });
  }

  scrollToRef = refId => () => {
    if (window.innerWidth < 768 && this.refs[refId]){
      this.refs[refId].scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
  }

  getInFavorites = () => {
    const { favorites } = this.props;

    const ids = _.reduce(favorites, function(result, value) {
      value.subcategories.map(subcategory1 => 
        subcategory1.subcategories.map(subcategory => 
          result.push(subcategory.id)
        )
      );

      return result;
    }, []);

    return ids;
  }

  getSortedSubcats = subcategories => {
    let sorted = [];

    subcategories.map(
      subcategory2 => subcategory2.subcategories.map(
        subcategory => sorted.push(subcategory)
      )
    );

    const chunk = Math.floor(sorted.length/4);

    return chunk === 0 ? [[sorted]] : _.chunk(_.chunk(_.sortBy(sorted, 'name'), chunk), 4);
  }

  searchAutocomplete = () => {
    const { style, searchAutocomplete } = this.props;
    const { search } = this.state;

    let searchData = [];

    if (!_.isEmpty(searchAutocomplete)){
      searchData = _.slice(searchAutocomplete.data, 0, 5);
    }

    return !_.isEmpty(search) 
      ? !_.isEmpty(searchData) 
        ? <div className={style.searchAutocomplete}>
            {_.map(searchData,
              (product, key) => <ProductCard key={`${key}_${product.id}`} product={product} className={'inline'} linkOnClick={this.handleClose} />
            )}
            <div className={style.searchAutocompleteBox} onClick={this.handleSearch}>
              <div className={style.searchAutocompleteTitle}>
                <FormattedMessage id="Categories.SEARCH_INFO" values={{ 
                  best: decOfNum(_.size(searchData), [<FormattedMessage id="Categories.BEST1" />, <FormattedMessage id="Categories.BEST2" />, <FormattedMessage id="Categories.BEST3" />]),
                  size: _.size(searchData), 
                  result: decOfNum(_.size(searchData), [<FormattedMessage id="Categories.RESULT1" />, <FormattedMessage id="Categories.RESULT2" />, <FormattedMessage id="Categories.RESULT3" />]) 
                }} />
              </div>
              <div className={style.searchAutocompleteText}>
                <strong>
                  <FormattedMessage id="Categories.GOOD_INFO" values={{
                    total: searchAutocomplete.total || 0,
                    goods: decOfNum(searchAutocomplete.total || 0, [<FormattedMessage id="Categories.GOOD1" />, <FormattedMessage id="Categories.GOOD2" />, <FormattedMessage id="Categories.GOOD3" />])
                   }} />
                </strong><br/>
                <button className={style.btnLink} onClick={this.handleSearch}><FormattedMessage id="Categories.CICK_HERE" /></button>, <FormattedMessage id="Categories.CICK_HERE_INFO" />
              </div>
            </div>
          </div>
        : <div className={style.searchAutocomplete}>
            <div className={classNames(style.searchAutocompleteBox, style.searchAutocompleteBoxEmpty)}>
              <div className={style.searchAutocompleteText}>
                <FormattedMessage id="Categories.EMPTY" /> <span role='img' aria-label='' className={style.emoji}>&#128528;</span>
              </div>
            </div>
          </div>
      : false;
  }

  boxFavorites = () => {
    const { style, favorites, toggleCategories, favoritesFetching } = this.props;
    const { editFavorite } = this.state;

    const activeCatId = this.getActiveCatId();

    return (
      <div className={style.boxFavorites}>
        <div className={classNames(style.heading, style.headingIcon)}>
          <div className={style.headingText}><FormattedMessage id="Categories.FAVORITES" /></div>
          <button className={classNames(style.headingButton, {[style.active]: editFavorite})} onClick={this.handleFavorite}>
            <FontAwesomeIcon icon="cog" />
            {editFavorite ? <FormattedMessage id="App.SAVE" /> : <FormattedMessage id="App.CONFIG" />}
          </button>
        </div>
        <div className={style.favorites}>
          {!_.isEmpty(favorites)
            ? favorites.map(favorite =>
                <div key={favorite.id} className={classNames(style.category, style.active)}>
                  <div className={classNames(style.categoryParent, style.orange)}>
                    <span className={style.categoryName}>{favorite.name}</span>
                  </div>
                  <div className={classNames(style.subcategories, style.column)}>
                    {favorite.subcategories.map(subcategory1 =>
                      subcategory1.subcategories.map(subcategory => 
                        editFavorite
                        ? <div key={subcategory.id} className={style.categorySubcat}>
                            <button className={style.removeCategory} title="Удалить" onClick={this.handleRemove(subcategory.id)}><FontAwesomeIcon icon="minus-circle" /></button>
                            <button 
                              className={style.categoryLink}
                              onClick={this.handleRemove(subcategory.id)}
                            >
                              {subcategory.name}
                            </button>
                          </div>
                        : <div key={subcategory.id} className={classNames(style.categorySubcat, {[style.active]: (+activeCatId === +subcategory.id)})}>
                            <Link 
                              className={style.categoryLink} 
                              to={'/catalog/'+subcategory.id}
                              onClick={toggleCategories}
                            >
                              {subcategory.name}
                            </Link>
                          </div>
                      )
                    )}
                  </div>
                </div>
              )
            : favoritesFetching
              ? <div className={style.moreLoader}><Loader /></div>
              : <div className={style.empty}><FormattedMessage id="Categories.FAVORITES_EMPTY" /> <span role='img' aria-label='' className={style.emoji}>&#128528;</span></div>
          }
        </div>
      </div>
    );
  }

  boxIncomes = () => {
    const { style, incomesFetching, incomes } = this.props;

    const incomesChunk = _.chunk(incomes, 3);

    return (
      <div className={style.boxIncomes}>
        <div className={style.heading}>
          <div className={style.headingText}><FormattedMessage id="Categories.INCOME" /></div>
        </div>
        <div className={style.subHeading}><FormattedMessage id="Categories.INCOME_INFO" /></div>
        <div className={style.incomes}>
          {!_.isEmpty(incomes)
            ? incomesChunk.map((incomeColumn, key) => 
                <div key={key} className={style.categoryColumn}>
                  {incomeColumn.map(income => {
                    const formatData = moment(income.month, 'DD/MM/YYYY').format('DD.MM');
                    const dataText = `${formatData}, ${income.supercat}`;
                    return (
                      <div key={income.id} className={style.categorySubcat}>
                        <a 
                          href={`${process.env.REACT_APP_COLORIT}public/income/${income.id}.xlsx`} 
                          download 
                          className={style.categoryLink}
                          title={dataText}
                        >
                          {dataText}
                        </a>
                      </div>
                    );
                  }
                  )}
                </div>
              )
            : incomesFetching
              ? <div className={style.moreLoader}><Loader /></div>
              : <div className={style.empty}><FormattedMessage id="Categories.INCOME_EMPTY" /></div>
          }
          {!_.isEmpty(incomes) && _.size(incomesChunk) < 3 && 
            <div className={classNames(style.categoryColumn, style.categoryColumnImage)}>
              <div className={style.incomeImages}>
                <img className={style.incomeImageShip} src={require('./img/ship.png')} alt='' />
                <span className={style.incomeImageLine}></span>
                <img className={style.incomeImageHouse} src={require('./img/warehouse.png')} alt='' />
              </div>
            </div>
          }
        </div>
      </div>
    );
  }

  handleClose = () => {
    const { toggleCategories, clearSearchAutocomplete } = this.props;

    clearSearchAutocomplete();
    toggleCategories();
    this.setState({search: ''});
  }

  getActiveCatId = () => {
    const { match } = this.props;

    return match.params && match.params.catId ? match.params.catId : null
  }; 

  render() {
    const { style, styleModal, open, categories, arrivals, toggleCategories, arrivalsFetching, arrivalsLimit, arrivalsEnd, lovedCategories, lovedCategoriesFetching } = this.props;
    const { editFavorite, activeNav, downloadPriceModal, downloadFee, downloadPriceProcess, lovedCategoriesChecked } = this.state;

    const activeCatId = this.getActiveCatId();

    const inFavorites = this.getInFavorites();

    const activeCategories = lovedCategoriesChecked ? lovedCategories : categories;

    const searchAutocomplete = this.searchAutocomplete();

    return (
      <div>
        <Modal
          isOpen={downloadPriceModal}
          onRequestClose={this.handlePriceModal(null)}
          content={
            <div className={styleModal.modal}>
              <div className={styleModal.modalHeading}><FormattedMessage id="Categories.PRICE_DOWNLOAD" /></div>
              <div className={styleModal.warning}><FormattedMessage id="Categories.PRICE_INFO" /></div>
              <div className={styleModal.form}>
                <label className={styleModal.formControl}>
                  <span><FormattedMessage id="Categories.PRICE_PERCENT" /></span>
                  <input 
                    className={styleModal.inputCenter} 
                    type='number' 
                    name='fee' 
                    onChange={this.handleFeeInput} 
                    onKeyDown={this.handleKeyDown} 
                    value={downloadFee}
                    autoFocus={true}
                  />
                </label>
                <div className={styleModal.btnsBox}>
                  <button className={styleModal.modalSbt} onClick={this.handleDownloadPrice} disabled={downloadPriceProcess}>
                    {downloadPriceProcess
                      ? <div className={styleModal.sbtLoader}>
                          <Loader />
                        </div>
                      : <FormattedMessage id="App.DOWNLOAD" />
                    }
                    </button>
                  <button className={styleModal.modalClose} onClick={this.handlePriceModal(null)}><FormattedMessage id="App.DOWNLOAD_CANCEL" /></button>
                </div>
              </div>
            </div>
          }
        />
        {open && 
          <div className={style.root}>
            <div className={style.searchBox}>
              <MediaQuery minWidth={1024}>
                <label htmlFor="CategoriesSearch" className={style.searchLabel}>
                  <FormattedMessage id="Categories.SEARCH" />
                </label>
              </MediaQuery>
              <div className={style.searchInputContainer}>
                <input type="text" autoComplete="off" id="CategoriesSearch" className={classNames(style.searchInput, {[style.withAutocomplete]: searchAutocomplete})} onKeyDown={this.handleSearchKeyDown} onChange={this.handleSearchChange}></input>
                <button className={style.searchBtn} onClick={this.handleSearch}><FontAwesomeIcon icon="search" /></button>
                {searchAutocomplete}
              </div>
              <MediaQuery minWidth={1024}>
                <button className={style.close} onClick={this.handleClose}><FontAwesomeIcon icon="times" /><FormattedMessage id="App.CLOSE" /></button>
              </MediaQuery>
            </div>
            <div className={style.container}>
              <div className={style.containerWrapper}>
                <div className={style.topBox}>
                  {/* Arrivals */}
                  <div className={style.boxArrivals}>
                    <div className={style.heading}><FormattedMessage id="Categories.ARRIVALS" /></div>
                    {!_.isEmpty(arrivals) &&
                      <div className={classNames(style.subcategories, style.column)}>
                        {arrivals.slice(0,arrivalsLimit).map((arrival, index) => 
                          <div key={index} className={style.categorySubcat}>
                            <Link 
                              className={style.categoryLink} 
                              to={'/arrival/'+arrival.url}
                              onClick={this.handleClose}
                            >
                              {arrival.text}
                            </Link>
                          </div>
                        )}
                        {!arrivalsFetching 
                          ? !arrivalsEnd && <button className={style.moreBtn} onClick={this.handleMoreArrivals} disabled={arrivalsFetching}>
                              <FormattedMessage id="App.SHOW_MORE_SM" /> <FontAwesomeIcon icon="angle-down" />
                            </button>
                          : <div className={style.moreLoader}><Loader /></div>
                        }
                      </div>
                    }
                    {_.isEmpty(arrivals) && arrivalsFetching &&
                      <div className={style.moreLoader}><Loader /></div>
                    }
                  </div>
                  {this.boxIncomes()}
                </div>
                {/* Category Desktop NAV */}
                <div className={style.categoriesNavsWrapper}>
                  <MediaQuery minWidth={768}>
                    <div className={style.categoriesNavs}>
                      {activeCategories.map((category, navIndex) => 
                        <button key={`catNav${category.id}`} className={style.categoryNav} onClick={this.handleCategoryNav(navIndex)}>
                          <div className={classNames(style.categoryParent, {[style.active]: activeNav === navIndex})}>
                            <span className={style.categoryName}>{category.name}</span>
                          </div>
                        </button>
                      )}
                    </div>
                  </MediaQuery>
                  {/* <label className={classNames(style.loveSwitch, {[style.checked]:lovedCategoriesChecked})}>
                    <Switch 
                      onChange={this.handleLoveChange} 
                      checked={lovedCategoriesChecked} 
                      width={44}
                      height={20}
                      activeBoxShadow='0 0 2px 3px #EB8C01'
                      onColor='#DE6A24'
                      uncheckedIcon={false}
                      checkedIcon={false}
                    />
                    <span><FormattedMessage id="Categories.ACTUAL" /></span>
                  </label> */}
                </div>
                <div className={style.categories}>
                  {/* Top Cat */}
                  {lovedCategoriesChecked && lovedCategoriesFetching 
                    ? <div className={style.categoryLoader}><Loader /></div>
                    : _.isEmpty(activeCategories)
                      ? <div className={style.empty}><FormattedMessage id="Categories.ORDER_INFO" /> <span role='img' aria-label='' className={style.emoji}>&#128526;</span></div>
                      : activeCategories.map((category, catIndex) => 
                          <div key={`cat${category.id}`} ref={`ref${catIndex}`}>
                            <MediaQuery maxWidth={767}>
                                <button key={`catNav${category.id}`} className={classNames(style.categoryNav, style.categoryNavMobile)} onClick={this.handleCategoryNav(catIndex)}>
                                  <div className={classNames(style.categoryParent, {[style.active]: activeNav === catIndex})}>
                                    <span className={style.categoryName}>{category.name}</span>
                                  </div>
                                </button>
                            </MediaQuery>
                            <CSSTransition in={activeNav === catIndex} key={`cat${category.id}`} timeout={{ enter: 300, exit: 200 }} classNames='fade' appear onEnter={this.scrollToRef(`ref${catIndex}`)}>
                              <div className={classNames(style.category, {[style.active]: activeNav === catIndex})}>
                                {!lovedCategoriesChecked && 
                                  <button className={style.download} onClick={this.handlePriceModal(category.id)}>
                                    <DownloadIcon />
                                    <FormattedMessage id="Categories.PRICE_IMG_DOWNLOAD" />
                                  </button>}
                                <div className={classNames(style.subcategoriesTable, {[style.loved]: lovedCategoriesChecked})}>
                                  {/* Level 2 */}
                                  {
                                    this.getSortedSubcats(category.subcategories).map(
                                      (row, rowIndex) => <div key={`row_${rowIndex}`} className={style.categoryRow}>
                                        {row.map(
                                          (column, colIndex) => <div key={`col_${colIndex}`} className={style.categoryColumn}>
                                            {column.map(subcategory => 
                                              editFavorite
                                                ? <div key={subcategory.id} className={style.categorySubcat}>
                                                    {inFavorites.includes(subcategory.id)
                                                      ? <button className={style.removeCategory} title='Удалить' onClick={this.handleRemove(subcategory.id)}><FontAwesomeIcon icon='minus-circle' /></button>
                                                      : <button className={style.addCategory} title='Добавить' onClick={this.handleAdd(subcategory.id)}><FontAwesomeIcon icon='plus-circle' /></button>
                                                    }
                                                    <button 
                                                      className={style.categoryLink} 
                                                      onClick={inFavorites.includes(subcategory.id) ? this.handleRemove(subcategory.id) : this.handleAdd(subcategory.id)}
                                                      title={subcategory.name}
                                                    >
                                                      {subcategory.name}
                                                    </button>
                                                  </div>
                                                : <div key={subcategory.id} className={classNames(style.categorySubcat, {[style.active]: (+activeCatId === +subcategory.id)})}>
                                                    <Link 
                                                      className={style.categoryLink} 
                                                      to={'/catalog/'+subcategory.id}
                                                      onClick={toggleCategories}
                                                      title={subcategory.name}
                                                    >
                                                      {subcategory.name}
                                                    </Link>
                                                  </div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    )
                                  }
                                </div>
                              </div>
                            </CSSTransition>
                          </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

Categories.defaultProps = {
  style: require('./Categories.module.scss'),
  styleModal: require('shared/styles/Modal.module.scss'),
  arrivalsPerPage: 5
};

Categories.propTypes = {
  categories: PropTypes.array.isRequired,
  categoriesFetching: PropTypes.bool.isRequired,
  arrivals: PropTypes.array.isRequired,
  arrivalsFetching: PropTypes.bool.isRequired,
  favorites: PropTypes.array.isRequired,
  favoritesFetching: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  toggleCategories: PropTypes.func.isRequired,
  fetchCategories: PropTypes.func.isRequired,
  fetchArrivals: PropTypes.func.isRequired,
  userToken: PropTypes.string.isRequired,
  setNotification: PropTypes.func.isRequired,
  arrivalsLimit: PropTypes.number.isRequired,
  arrivalsEnd: PropTypes.bool.isRequired,
  lovedCategories: PropTypes.array.isRequired,
  lovedCategoriesFetching: PropTypes.bool.isRequired,
  fetchLovedCategories: PropTypes.func.isRequired,
  clearSearchAutocomplete: PropTypes.func.isRequired,
  incomes: PropTypes.array.isRequired,
  incomesFetching: PropTypes.bool.isRequired,
  fetchIncomes: PropTypes.func.isRequired,
};

export default Categories;