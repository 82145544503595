import React, { Component } from 'react';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import { apiSendMessage } from './services';

class ContactForm extends Component {
  state = {
    comment: '',
    type: 'mail'
  }

  handleComment = e => {
    this.setState({comment: e.target.value});
  }

  handleType = e => {
    this.setState({type: e.target.value});
  }

  handleConfirm = () => {
    const { setNotification, userToken } = this.props;
    const { comment } = this.state;

    const that = this;

    if (_.isEmpty(comment)){
      setNotification('messageRequired');
    }else{
      apiSendMessage(this.state, userToken).then(function (response) {
        if (response.data){
          setNotification('messageSuccess', 'success');
          that.setState({type: 'mail', comment: ''});
        }else{
          setNotification('error');
        }
      }).catch(function (error) {
        setNotification('error');
      });
    }
  }

  render() {
    const { style, sendingMessage } = this.props;
    const { comment, type } = this.state;

    const _oldForm = <div className={style.root}>
                      <div className={style.heading}><FormattedMessage id="ContactForm.HEADING" /></div>
                      <div className={style.info}>
                        <div className={style.comment}>
                          <textarea className={style.commentTextarea} onChange={this.handleComment} value={ comment }></textarea>
                        </div>
                        <div className={style.radio}>
                          <div className={style.radioLabel}><FormattedMessage id="ContactForm.TITLE" /></div>
                          <label>
                            <input type='radio' value='mail' onChange={this.handleType} checked={type==='mail'} /><FormattedMessage id="ContactForm.EMAIL" />
                          </label>
                          <label>
                            <input type='radio' value='phone' onChange={this.handleType} checked={type==='phone'} /><FormattedMessage id="ContactForm.PHONE" />
                          </label>
                        </div>
                        <div className={style.btnWrapper}>
                          {sendingMessage
                            ? <div className={style.btnLoaderOrder}><Loader /></div>
                            : <button className={style.btnOrder} onClick={this.handleConfirm}><FormattedMessage id="ContactForm.SEND" /></button>
                          }
                        </div>
                      </div>
                    </div>

    const _newForm = <div className={style.root}>
                        <div className={style.heading}><FormattedMessage id="ContactForm.NEW_HEADING" /></div>
                        <div className={style.items}>
                          <a className={style.itemLink} target='_blank' href='tel:+380507779777'>
                            <span className={style.itemIconPhone}></span>
                            <span className={style.itemContent}>
                              <FormattedMessage id="ContactForm.NEW_PHONE" />
                              <span className={style.itemVal}>+38 (050) 777-9-777</span>
                            </span>
                          </a>
                          <div className={style.item}>
                            <span className={style.itemIconMessangers}></span>
                            <span className={style.itemContent}>
                              <FormattedMessage id="ContactForm.NEW_MESSANGER" />
                              <span className={style.itemVal}><a target='_blank' href='https://t.me/sparta_manager'>Telegram</a> aбо <a target='_blank' href='viber://chat?number=+380507779777'>Вайбер</a></span>
                            </span>
                          </div>
                          <a className={style.itemLink} target='_blank' href="https://www.google.com/maps/place/Frantsuz'ky+Blvd,+66%2F2,+Odesa,+Odes'ka+oblast,+Ukraine,+65000/@46.4390735,30.7693108,17z/data=!4m6!3m5!1s0x40c633f69d5b0871:0x4283b6a7d4c31fb3!8m2!3d46.4391029!4d30.7689311!16s%2Fg%2F11gr6jjs9z?entry=ttu&g_ep=EgoyMDI1MDEyNi4wIKXMDSoASAFQAw%3D%3D">
                            <span className={style.itemIconMap}></span>
                            <span className={style.itemContent}>
                              <FormattedMessage id="ContactForm.NEW_ADDRESS" />
                              <span className={style.itemVal}><FormattedMessage id="ContactForm.NEW_ADDRESS_INFO" /></span>
                            </span>
                          </a>
                        </div>
                     </div>

    return _newForm;
  }
}

ContactForm.defaultProps = {
  style: require('./ContactForm.module.scss'),
}

ContactForm.propTypes = {
  userToken: PropTypes.string.isRequired,
  setNotification: PropTypes.func.isRequired
};

export default ContactForm;