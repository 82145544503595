import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import CartModal from 'components/Cart/components/CartModal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class MobileMenu extends Component {
  handleMenu = () => {}

  render() {
    const { style } = this.props;

    return(
      <div className={style.root}>
        <div className={style.container}>
          <nav className={style.navTop}>
            <div className={style.navItem}>
              <CartModal modificator='mobileMenu' />
            </div>
            <div className={style.navItem}>
              <NavLink className={style.navCabinet} activeClassName={style.active} to='/login'>
                <FontAwesomeIcon icon='user' />
                <span><FormattedMessage id="App.CABINET" /></span>
              </NavLink>
            </div>
          </nav>
          <nav className={style.navBottom}>
            <div className={style.navItem}>
              <NavLink className={style.navLink} activeClassName={style.active} to='/partners'>
                <span><FormattedMessage id="App.PARTNERS" /></span>
              </NavLink>
            </div>
            <div className={style.navItem}>
              <NavLink className={style.navLink} activeClassName={style.active} to='/about'>
                <span><FormattedMessage id="App.ABOUT" /></span>
              </NavLink>
            </div>
            <div className={style.navItem}>
              <NavLink className={style.navLink} activeClassName={style.active} to='/colorit'>
                <span>TM COLOR-IT / CELLO / ELLOT</span>
              </NavLink>
            </div>
            <div className={style.navItem}>
              <NavLink className={style.navLink} activeClassName={style.active} to='/delivery'>
                <span><FormattedMessage id="App.DELEVERY_PAYMENT" /></span>
              </NavLink>
            </div>
            <div className={style.navItem}>
              <NavLink className={style.navLink} activeClassName={style.active} to='/contact'>
                <span><FormattedMessage id="App.CONTACT" /></span>
              </NavLink>
            </div>        
          </nav>
        </div>
      </div>
    )
  }
}

MobileMenu.defaultProps = {
  style: require('./MobileMenu.module.scss')
}

export default MobileMenu;