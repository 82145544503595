import { LOCALES } from "./locales";

export const messages = {
  [LOCALES.RUSSIAN]: {
    'App.YES': 'Да',
    'App.NO': 'Нет',
    'App.NOT_SET': 'не указано',
    'App.SHOW_MORE': 'ПОКАЗАТЬ ЕЩЕ',
    'App.SHOW_MORE_SM': 'Показать еще',
    'App.ABOUT': 'О КОМПАНИИ',
    'App.DELEVERY_PAYMENT': 'ДОСТАВКА И ОПЛАТА',
    'App.CONTACT': 'КОНТАКТЫ',
    'App.PARTNERS': 'ПАРТНЕРЫ',
    'App.CABINET': 'Кабинет',
    'App.SAVE': 'Сохранить',
    'App.SAVE_UP': 'СОХРАНИТЬ',
    'App.CONFIG': 'Настройка',
    'App.DOWNLOAD': 'Скачать',
    'App.DOWNLOAD_SM': 'скачать',
    'App.CLOSE': 'Закрыть',
    'App.BALANCE': 'БАЛАНС',
    'App.VIEW_HISTORY': 'ИСТОРИЯ ПРОСМОТРОВ',
    'App.SPECIAL': 'СКИДКИ',
    'App.PROFILE': 'МОЙ ПРОФИЛЬ',
    'App.EXIT': 'Выход',
    'App.NEXT': 'Продолжить',
    'App.EDIT': 'изменить',
    'App.ADD_SM': 'добавить',
    'App.ADD': 'Добавить',
    'App.DOWNLOAD_CANCEL': 'Отменить скачивание',
    'App.CHANGE_CANCEL': 'Отменить изменения',
    'App.REMOVE': 'Удалить',
    'App.MANAGER_1': 'Елена',
    'App.MANAGER_2': 'Наталья',
    'App.MANAGER_3': 'Дмитрий',
    'About.TITLE': 'О компании',
    'About.HEADING': 'ОТКРОЙТЕ ДЛЯ СЕБЯ НОВЫЕ ВОЗМОЖНОСТИ',
    'About.BOX_HEADING': 'ПРОДУКТЫ, КОТОРЫМИ МЫ ГОРДИМСЯ',
    'About.BOX_TEXT': 'Наша команда вложила много сил и энергии, чтобы сегодня иметь возможность создавать и продавать качественные канцелярские товары по лучшим на рынке ценам. Соотношение цены и качества, лучше которых Вы не найдете. И это не будет сделкой с совестью, наши товары действительно хорошее приобретение.',
    'About.BOX_AUTHOR': '— Андрей Ченцов, ваш слуга и генеральный директор Color-It',
    'About.INFO_1_TITLE': 'Лучшие цены',
    'About.INFO_1_TEXT': 'Мы понимаем важность иметь лучшую цену на рынке и будьте уверены — мы обеспечим вам это и другие конкурентные преимущества',
    'About.INFO_2_TITLE': 'Доставка в срок',
    'About.INFO_2_TEXT': 'Налаженные логистические процессы и оптимизация склада позволяют нам отправлять заказы любого объема всегда вовремя',
    'About.INFO_3_TITLE': 'Партнерство',
    'About.INFO_3_TEXT': 'Уважение к партнерам и ценность взаимоотношений — это то, что для нас действительно важно. Мы любим то, что делаем и ценим своих бизнес-партнеров',
    'About.TEXT_1': 'Наша компания уже {fromYear} занимается оптовой торговлей на рынке Украины — мы начали свой путь с продажи канцелярских товаров и стали безусловными лидерами  в этой области, обеспечивая клиентов качественными товарами, лучшим ценами и постоянной поддержкой со стороны наших менеджеров.',
    'About.TEXT_2': 'Мы внимательно следим за инновациями и всегда в курсе последних новинок, чтобы обеспечивать наших клиентов только актуальными товарами.',
    'About.TEXT_3': 'Сегодня наша команда — сочетание многолетнего опыта и слаженной работы всех подразделений компании делает нас  одной из лучших компаний Украины. Мы постоянно развиваемся, все время улучшая качество обслуживания клиентов и удобство работы с интернет-магазином, внимательно прислушиваемся ко всем пожеланиям и предложениям покупателей, чтобы гарантировать максимальную выгоду и удобство сотрудничества с нашей компанией.',
    'Balance.BALANCE': 'Баланс',
    'Balance.DATE': 'Дата',
    'Balance.DEBT': 'Дебет',
    'Balance.CREDIT': 'Кредит',
    'Balance.REMAINDER': 'Остаток',
    'Balance.COMMENT': 'Комментарий',
    'Balance.INVOICE': 'Накладная',
    'Balance.EMPTY': 'Тут пока еще нет заказов :(',
    'BalanceSms.PASS_HEADING': 'Введите пароль',
    'BalanceSms.PASS_TEXT': 'Мы отправили одноразовый пароль на номер',
    'BalanceSms.PASS_SMS_HEADING': 'Для доступа к данному разделу, пожалуйста, запросите пароль.',
    'BalanceSms.PASS_SMS': 'Получить СМС',
    'BalanceSms.PASS_SMS_INFO': 'Раздел будет доступен в течении часа.',
    'Breadcrumbs.BACK': 'ВЕРНУТЬСЯ К СПИСКУ ТОВАРОВ',
    'Cabinet.GREETING': 'Рады вас видеть {name}',
    'CatalogMenu.CATALOG': 'КАТАЛОГ',
    'Categories.SEARCH': 'ПОИСК ПО КАТАЛОГУ',
    'Categories.SEARCH_INFO': 'Это {best} {size} {result}',
    'Categories.BEST1': 'лучший',
    'Categories.BEST2': 'лучшие',
    'Categories.BEST3': 'лучшие',
    'Categories.RESULT1': 'результат',
    'Categories.RESULT2': 'результата',
    'Categories.RESULT3': 'результатов',
    'Categories.GOOD_INFO': 'Всего мы нашли {total} {goods} по вашему запросу.',
    'Categories.GOOD1': 'товар',
    'Categories.GOOD2': 'товара',
    'Categories.GOOD3': 'товаров',
    'Categories.CICK_HERE': 'Нажмите здесь',
    'Categories.CICK_HERE_INFO': 'чтобы посмотреть все результаты или просто нажмите Enter.',
    'Categories.EMPTY': 'Мы ничего не нашли по вашему запросу',
    'Categories.FAVORITES': 'ИЗБРАННЫЕ КАТЕГОРИИ',
    'Categories.FAVORITES_EMPTY': 'В Ваших избранных пусто',
    'Categories.INCOME': 'ОЖИДАЕМ В БЛИЖАЙШЕЕ ВРЕМЯ',
    'Categories.INCOME_INFO': 'Планируйте свои заказы заранее — ознакомьтесь с ассортиментом, который уже в пути на наш склад и ожидается в наличии в самое ближайшее время.',
    'Categories.INCOME_EMPTY': 'Новое поступление в пути, совсем скоро здесь появятся товары, которые мы ждем.',
    'Categories.PRICE_DOWNLOAD': 'Скачать прайс-лист',
    'Categories.PRICE_INFO': 'Вы можете установить наценку, чтобы включить ее в стоимость товаров в скачанном файле',
    'Categories.PRICE_PERCENT': 'Наценка, %',
    'Categories.ARRIVALS': 'НОВЫЕ ПОСТУПЛЕНИЯ',
    'Categories.ACTUAL': 'Актуально',
    'Categories.ORDER_INFO': 'Оформите заказ и мы сохраним категории товаров тут',
    'Categories.PRICE_IMG_DOWNLOAD': 'Скачать прайс-лист с картинками',
    'Catalog.CLEAR': 'ОЧИСТИТЬ СПИСОК',
    'Catalog.ALL_GOODS': 'Все товары',
    'Catalog.INV_DOWNLOAD': 'Скачать инвойс',
    'Catalog.INV_DOWNLOAD_TEXT': 'Вы можете установить наценку, чтобы включить ее в стоимость товаров в скачанном файле',
    'Catalog.INV_PERCENT': 'Наценка, %',
    'Catalog.INV': 'ИНВОЙС {inv}',
    'Catalog.EMPTY_HEADING': 'ТУТ НИЧЕГО НЕТ',
    'Catalog.EMPTY': 'Похоже, мы распродали все товары из этой категории.',
    'Catalog.EMPTY_TEXT': 'Следите за новыми поступлениями, чтобы быть в курсе последних поставок. А пока можете посмотреть товары в других категориях, возможно, вы найдете что-то интересное.',
    'CartConfirm.TOTAL': 'Всего к оплате',
    'CartConfirm.INFO': 'Если у вас есть какие-то пожелания по сборке или упаковке заказанных вами позиций, напишите нам об этом',
    'CartConfirm.RECIPIENT_CHOSE': 'Выберите получателя',
    'CartConfirm.RECIPIENT_EMPTY': 'Нет получателей',
    'CartConfirm.RECIPIENT_ADD': 'Добавить получателя',
    'CartConfirm.CONFIRM': 'ОФОРМИТЬ ЗАКАЗ',
    'CartOrder.HEADING_INFO': 'ПОДТВЕРЖДЕНИЕ ЗАКАЗА',
    'CartOrder.HEADING_DELEVERY': 'БЕСПЛАТНАЯ ДОСТАВКА',
    'CartOrder.TEXT_DELEVERY': 'Мы полностью покрываем расходы на доставку, вы оплачиваете только стоимость заказа. Кроме этого, весь товар застрахован и, в случае любых повреждений, компания-перевозчик возмещает полную стоимость.',
    'CartOrder.TEXT_INFO': 'После заказа вам обязательно перезвонит менеджер и уточнит все детали, необходимые для сборки заказа.',
    'CartOrder.EMPTY': 'Ваша корзина пуста!',
    'CartSuccess.ORDER_NUM': 'Номер вашего заказа',
    'CartSuccess.ORDER_QTY': 'Количество товаров',
    'CartSuccess.ORDER_SUM': 'Сумма заказа',
    'CartSuccess.INFO': 'В ближайшее время мы свяжемся с вами для подтверждения заказа и согласования деталей доставки и оплаты.',
    'CartSuccess.BOTTOM_HEADING': 'Совсем скоро мы отправим ваш заказ',
    'CartSuccess.BOTTOM_TEXT': 'Партнерские отношения с компанией Новая Почта дают нам определенные преимущества, которые позволяют отправлять заказы наших клиентов в максимально сжатые сроки. Таким образом, вы получите свой заказ примерно за 36 часов с момента подтверждения менеджером или за 24 часа, если заказ. сделан в первой половине дня.',
    'CartSuccess.REVIEW_HEADING': 'Пожалуйста, оцените нашу работу',
    'CartSuccess.REVIEW_TEXT_1': 'Каждый день мы работаем над тем, чтобы сделать наш магазин и качество обслуживания еще лучше и удобнее для вас.',
    'CartSuccess.REVIEW_TEXT_2': 'Если у вас возникли какие-то пожелания или замечания по работе нашего магазина, пожалуйста, напишите нам об этом с помощью {link}, или любым удобным вам способом.',
    'CartSuccess.REVIEW_CONTACT_FORM': 'формы обратной связи',
    'Contact.TITLE': 'Свяжитесь с нами',
    'Contact.ADDRESS': 'Промтоварный рынок «7 километр,{br}Овидиопольская дор., 2096,{br}Одесса, Одесская область,{br}65000',
    'ContactForm.HEADING': 'Отправьте нам сообщение',
    'ContactForm.TITLE': 'Как с вами лучше связаться?',
    'ContactForm.EMAIL': 'По почте',
    'ContactForm.PHONE': 'По телефону',
    'ContactForm.SEND': 'ОТПРАВИТЬ СООБЩЕНИЕ',
    'ChangeProfile.TITLE': 'Изменение персональных данных',
    'ChangeProfile.INFO': 'В целях безопасности изменение некоторых данных невозможно. Если вы все же хотите внести изменения, пожалуйста, свяжитесь с вашим персональным менеджером',
    'Delivery.TITLE': 'Доставка и оплата',
    "Delivery.FREE_SHIPPING": "ДОСТАВКА {free} ВСЕГДА",
    "Delivery.FREE": "БЕСПЛАТНА",
    "Delivery.INDIVIDUAL_DISCOUNTS": "Также у нас на постоянной основе действуют {text}. {br}Свяжитесь с менеджером, чтобы узнать вашу.",
    "Delivery.INDIVIDUAL_DISCOUNT_TEXT": "индивидуальные скидки",
    "Delivery.MIN_ORDER": "Сумма минимального заказа",
    "Delivery.PAYMENT_TITLE": "Оплата",
    "Delivery.PAYMENT_TEXT": "Мы всегда найдём способ, который подойдёт всем",
    "Delivery.SHIPPING_TITLE": "Доставляем",
    "Delivery.SHIPPING_TEXT": "Новая Почта, Укрпочта или любая другая доставка, которая будет удобна именно для вас",
    'Delivery.BOTTOM_1_TITLE': 'Обработка заказов',
    'Delivery.BOTTOM_1_TEXT_1': 'После получения заказа, при необходимости, наши менеджеры свяжутся с вами и подтвердят всю необходимую информацию.',
    'Delivery.BOTTOM_1_TEXT_2': 'Обратите внимание, что если заказ был сделан в первой половине дня, то он отправляется на следующий день, если во второй — то через день.',
    'Delivery.BOTTOM_1_TEXT_3': 'Срок доставки по Украине от одного до двух дней после сборки заказа.',
    'Delivery.BOTTOM_2_TITLE': 'Минимальный заказ',
    'Delivery.BOTTOM_2_TEXT_1': 'Мы оптовая компания, и чтобы качественно обслуживать наших клиентов, у нас существует сумма минимального заказа.',
    'Delivery.BOTTOM_2_TEXT_2': 'Это позволяет нам максимально сосредоточится на эффективности внутренних складских и логистических процессов, чтобы обеспечивать то качество товаров и услуг, к которым привыкли наши клиенты.',
    'Delivery.BOTTOM_2_TEXT_3': 'В месяцы высокого сезона (июль-август) сумма минимального заказа может быть увеличена.',
    'Delivery.BOTTOM_3_TITLE': 'Страхование заказов',
    'Delivery.BOTTOM_3_TEXT_1': 'Мы не можем нести ответственность за повреждения товара в процессе транспортировки. Со своей стороны мы упаковываем товар таким образом, чтобы минимизировать возможные повреждения, а также страхуем весь товар на 100% от стоимости заказа.',
    'Delivery.BOTTOM_3_TEXT_2': 'Если продукция в вашем заказе была повреждена при перевозке, пожалуйста, обратитесь с претензией в офис компании-перевозчика — они обязаны возместить вам полную стоимость испорченного товара по цене, указанной в накладной.',
    'Good.NOT_AVAILABLE': 'Нет в наличии',
    'Good.OUT': 'Не доступен',
    'ListProducts.HEADING': 'Ваш заказ',
    'ListProducts.TOTAL': 'Всего на сумму',
    'Login.ENTER': 'Вход',
    'Login.ENTER_INFO': 'Для входа введите свой номер телефона в формате',
    'Login.PASSWORD': 'Введите пароль',
    'Login.PASSWORD_INFO': 'Мы отправили одноразовый пароль на номер {phone}',
    'Login.SMS': 'Не получили SMS? {link1} или {link2}',
    'Login.RESEND': 'Отправьте его еще раз',
    'Login.NEW_NUMBER': 'введите другой номер',
    'Login.SMS_WAIT': 'Ожидайте SMS на указанный номер',
    'Login.ENTER_CLIENT': 'Вход для клиентов',
    'Login.REGISTER': 'Регистрация',
    'Login.REGISTER_INFO': 'Пожалуйста, введите дополнительные данные для регистарции на сайте',
    'Login.REGISTER_BTN': 'Зарегистрироваться',
    'Maintenance.TITLE': 'Сайт на техническом обслуживании',
    'Maintenance.HEADING': 'ПРЯМО СЕЙЧАС МЫ КОЕ-ЧТО МЕНЯЕМ, ПОЭТОМУ САЙТ НЕ РАБОТАЕТ',
    'Maintenance.INFO_1': 'Мы стараемся делать такие вещи в часы минимальной нагрузки на {br} проект и нам очень жаль, что вы попали к нам именно в этот момент.',
    'Maintenance.INFO_2': 'Пожалуйста, зайдите немного позже или свяжитесь с одним из наших {br} менеджеров, если у вас что-то срочное. Спасибо за понимание.',
    'Manager.TITLE': 'Ваш персональный менеджер',
    'Manager.ALT': 'Менеджер {name}',
    'Order.TITLE': 'Заказ {orderId}',
    'Order.INVOICE': 'накладная',
    'Order.CART_ADD': 'ДОБАВИТЬ ЭТОТ ЗАКАЗ В КОРЗИНУ',
    'OrdersTable.CONFIRM_DELETE': 'Вы уверены, что хотите удалить заказ?',
    'OrdersTable.DATE': 'Дата',
    'OrdersTable.NUMBER': 'Номер',
    'OrdersTable.SUM': 'Сумма, {currency}',
    'OrdersTable.IN_ORDER': 'Что в заказе',
    'OrdersTable.STATUS': 'Статус',
    'OrdersTable.SEE': 'просмотреть',
    'OrdersTable.DOWNLOAD': 'скачать накладную',
    'OrdersTable.ADD_IN_CART': 'добавить в корзину',
    'OrdersTable.EMPTY': 'Тут пока еще нет заказов :(',
    'Partners.HEADING': 'Мы так привыкли к конкуренции, что, когда достойных соперников не осталось, решили соревноваться сами с собой',
    'Partners.TITLE': 'МЫ ЭКСКЛЮЗИВНО ПРЕДСТАВЛЯЕМ БРЕНДЫ CELLO И ELLOT В УКРАИНЕ',
    'Partners.OFICIAL': 'ОФИЦИАЛЬНО ЗАРЕГИСТРИРОВАННЫЕ В ТОРГОВОЙ ПАЛАТЕ КИТАЯ МАРКА И ЗНАК',
    'Profile.TITLE': 'Персональные данные',
    'Profile.USER_NAME': 'Имя и фамилия',
    'Profile.USER_CITY': 'Город',
    'Profile.USER_CITY_CHOSE': 'Выберите город',
    'Profile.USER_PHONE': 'Номера телефонов',
    'Profile.USER_EMAIL': 'Электронная почта',
    'Profile.RECIPIENT': 'Данные получателя',
    'Profile.RECIPIENT_FULLNAME': 'Имя и фамилия получателя',
    'Profile.RECIPIENT_NAME': 'Название',
    'Profile.RECIPIENT_PHONE': 'Номер телефона',
    'Profile.RECIPIENT_PHONE_ADD': 'Телефон',
    'Profile.RECIPIENT_PAYMENT': 'Способ оплаты',
    'Profile.RECIPIENT_PAYMENT_CHOSE': 'Выберите способ оплаты',
    'Profile.RECIPIENT_DELEVERY': 'Способ доставки',
    'Profile.RECIPIENT_DELEVERY_CHOSE': 'Выберите способ доставки',
    'Profile.RECIPIENT_WAREHOUSE': 'Отделение',
    'Profile.RECIPIENT_WAREHOUSE_CARGO': 'Отделение перевозчика',
    'Profile.RECIPIENT_WAREHOUSE_CHOSE': 'Выберите отделение',
    'Profile.RECIPIENT_ADDRESS': 'Адрес доставки (улица, дом, квартира)',
    'Profile.RECIPIENT_AREA': 'Область',
    'Profile.RECIPIENT_AREA_CHOSE': 'Выберите область',
    'Profile.RECIPIENT_PAYMENT_ADD': 'Добавление получателя',
    'Profile.RECIPIENT_EMPTY': 'У вас нет ни одного получателя. Пожалуйста, {btn} его.',
    'Profile.RECIPIENT_EMPTY_ADD': 'добавьте',
    'Product.END': 'Закончился',
    'Product.OUT': 'Заканчивается',
    'Product.CODE': 'Код товара: {productId}',
    'Product.INPACK': 'В ящике {inpack} штук',
    'Product.PRICE': 'Цена за штуку',
    'Product.QTY': 'Количество',
    'Product.IN_CART': 'В корзинe',
    'Product.ADD_IN_CART': 'В корзину',
    'Product.RRP': 'Рекомендуемая наценка {rrp}%',
    'Product.DELEVERY': 'БЫСТРАЯ ДОСТАВКА',
    'Product.INSURED': 'ТОВАР ЗАСТРАХОВАН',
    'Product.WARANTY': 'ГАРАНТИЯ КАЧЕСТВА',
    'Product.RELATED': 'Рекомендуем обратить внимание на эти товары',
    'Product.VIEWED': 'Просмотренные товары',
    'Product.IMG_CHOISE': 'Выбирайте по картинкам',
    'Statistic.STATUS': 'Ваш текущий статус {status}',
    'Statistic.CONTACT': 'Свяжитесь с менеджером, чтобы узнать, как изменить статус',
    'Statistic.CONTRACT': 'Договор',
    'Statistic.TURNOVER': 'Оборот {turnover}',
    'Statistic.TURNOVER_INFO': 'Это сумма заказов с начала года',
    'Statistic.DONE': 'Выполнено',
    'Statistic.DONE_ORDERS': 'Заказов{br}в {year} году',
    'Statistic.ORDERS': 'Заказы за этот год',
    'Statistic.LAST_ORDER': 'Ваш прошлый заказ мы доставили {time}',
    'Statistic.HOUR1': 'час',
    'Statistic.HOUR2': 'часа',
    'Statistic.HOUR3': 'часов',
  },
  [LOCALES.UKRAINIAN]: {
    'App.YES': 'Так',
    'App.NO': 'Ні',
    'App.NOT_SET': 'не вказано',
    'App.SHOW_MORE': 'ПОКАЗАТИ ЩЕ',
    'App.SHOW_MORE_SM': 'Показати ще',
    'App.ABOUT': 'ПРО КОМПАНІЮ',
    'App.DELEVERY_PAYMENT': 'ДОСТАВКА І ОПЛАТА',
    'App.CONTACT': 'КОНТАКТИ',
    'App.PARTNERS': 'ПАРТНЕРИ',
    'App.CABINET': 'Кабінет',
    'App.SAVE': 'Зберегти',
    'App.SAVE_UP': 'ЗБЕРЕГТИ',
    'App.CONFIG': 'Налаштування',
    'App.DOWNLOAD': 'Завантажити',
    'App.DOWNLOAD_SM': 'завантажити',
    'App.CLOSE': 'Закрити',
    'App.BALANCE': 'БАЛАНС',
    'App.VIEW_HISTORY': 'ІСТОРІЯ ПЕРЕГЛЯДІВ',
    'App.SPECIAL': 'ЗНИЖКИ',
    'App.PROFILE': 'МІЙ ПРОФІЛЬ',
    'App.EXIT': 'Вихід',
    'App.NEXT': 'Продовжити',
    'App.EDIT': 'змінити',
    'App.ADD_SM': 'додати',
    'App.ADD': 'Додати',
    'App.DOWNLOAD_CANCEL': 'Скасувати скачування',
    'App.CHANGE_CANCEL': 'Скасувати зміни',
    'App.REMOVE': 'Видалити',
    'App.MANAGER_1': 'Олена',
    'App.MANAGER_2': 'Наталя',
    'App.MANAGER_3': 'Дмитро',
    'About.TITLE': 'Про компанію',
    'About.HEADING': 'ВІДКРИЙТЕ ДЛЯ СЕБЕ НОВІ МОЖЛИВОСТІ',
    'About.BOX_HEADING': 'ПРОДУКТИ, ЯКИМИ МИ ПИШАЄМОСЯ',
    'About.BOX_TEXT': 'Наша команда вклала багато сил та енергії, щоб сьогодні мати можливість створювати та продавати якісні канцелярські товари за найкращими на ринку цінами. Співвідношення ціни та якості, краще за які Ви не знайдете. І це не буде угодою з совістю, наші товари справді гарне придбання.',
    'About.BOX_AUTHOR': '— Андрій Ченцов, ваш слуга та генеральний директор Color-It',
    'About.INFO_1_TITLE': 'Найкращі ціни',
    'About.INFO_1_TEXT': 'Ми розуміємо важливість мати найкращу ціну на ринку і будьте певні — ми забезпечимо вам це та інші конкурентні переваги',
    'About.INFO_2_TITLE': 'Доставка у термін',
    'About.INFO_2_TEXT': 'Налагоджені логістичні процеси та оптимізація складу дозволяють нам надсилати замовлення будь-якого обсягу завжди вчасно',
    'About.INFO_3_TITLE': 'Партнерство',
    'About.INFO_3_TEXT': 'Повага до партнерів та цінність взаємин — це те, що для нас справді важливо. Ми любимо те, що робимо та цінуємо своїх бізнес-партнерів',
    'About.TEXT_1': 'Наша компанія вже {fromYear} займається оптовою торгівлею на ринку України — ми розпочали свій шлях з продажу канцелярських товарів і стали безумовними лідерами в цій галузі, забезпечуючи клієнтів якісними товарами, найкращими цінами та постійною підтримкою з боку наших менеджерів.',
    'About.TEXT_2': 'Ми уважно стежимо за інноваціями і завжди в курсі останніх новинок, щоб забезпечувати наших клієнтів лише актуальними товарами.',
    'About.TEXT_3': 'Сьогодні наша команда — поєднання багаторічного досвіду та злагодженої роботи всіх підрозділів компанії робить нас однією з найкращих компаній України. Ми постійно розвиваємося, постійно покращуючи якість обслуговування клієнтів та зручність роботи з інтернет-магазином, уважно прислухаємося до всіх побажань та пропозицій покупців, щоб гарантувати максимальну вигоду та зручність співпраці з нашою компанією.',
    'Balance.BALANCE': 'Баланс',
    'Balance.DATE': 'Дата',
    'Balance.DEBT': 'Дебет',
    'Balance.CREDIT': 'Кредит',
    'Balance.REMAINDER': 'Залишок',
    'Balance.COMMENT': 'Коментар',
    'Balance.INVOICE': 'Накладна',
    'Balance.EMPTY': 'Тут поки що немає замовлень :(',
    'BalanceSms.PASS_HEADING': 'Введіть пароль',
    'BalanceSms.PASS_TEXT': 'Ми надіслали одноразовий пароль на номер',
    'BalanceSms.PASS_SMS_HEADING': 'Для доступу до цього розділу, будь ласка, запитайте пароль.',
    'BalanceSms.PASS_SMS': 'Отримати СМС',
    'BalanceSms.PASS_SMS_INFO': 'Розділ буде доступний протягом години.',
    'Breadcrumbs.BACK': 'ПОВЕРНУТИСЯ ДО СПИСКУ ТОВАРІВ',
    'Cabinet.GREETING': 'Раді вас бачити, {name}!',
    'CatalogMenu.CATALOG': 'КАТАЛОГ',
    'Categories.SEARCH': 'ПОШУК ПО КАТАЛОГУ',
    'Categories.SEARCH_INFO': 'Це {best} {size} {result}',
    'Categories.BEST1': 'кращий',
    'Categories.BEST2': 'кращі',
    'Categories.BEST3': 'кращі',
    'Categories.RESULT1': 'результат',
    'Categories.RESULT2': 'результати',
    'Categories.RESULT3': 'результатів',
    'Categories.GOOD_INFO': 'Всього ми знайшли {total} {goods} на ваш запит.',
    'Categories.GOOD1': 'товар',
    'Categories.GOOD2': 'товари',
    'Categories.GOOD3': 'товарів',
    'Categories.CICK_HERE': 'Натисніть тут',
    'Categories.CICK_HERE_INFO': 'щоб переглянути всі результати або просто натисніть Enter.',
    'Categories.EMPTY': 'Ми нічого не знайшли за вашим запитом',
    'Categories.FAVORITES': 'ОБРАНІ КАТЕГОРІЇ',
    'Categories.FAVORITES_EMPTY': 'У ваших обраних порожньо',
    'Categories.INCOME': 'ОЧІКУЄМО В НАЙБЛИЖЧИЙ ЧАС',
    'Categories.INCOME_INFO': 'Плануйте свої замовлення заздалегідь - ознайомтеся з асортиментом, який вже в дорозі на наш склад і очікується в наявності найближчим часом.',
    'Categories.INCOME_EMPTY': 'Нове надходження в дорозі, зовсім скоро тут з\'являться товари, на які ми чекаємо.',
    'Categories.PRICE_DOWNLOAD': 'Завантажити прайс-лист',
    'Categories.PRICE_INFO': 'Ви можете встановити націнку, щоб включити її у вартість товарів у завантаженому файлі',
    'Categories.PRICE_PERCENT': 'Націнка, %',
    'Categories.ARRIVALS': 'НОВІ НАДХОДЖЕННЯ',
    'Categories.ACTUAL': 'Актуально',
    'Categories.ORDER_INFO': 'Оформіть замовлення і ми збережемо категорії товарів тут',
    'Categories.PRICE_IMG_DOWNLOAD': 'Завантажити прайс-лист з картинками',
    'Catalog.CLEAR': 'ОЧИСТИТИ СПИСОК',
    'Catalog.ALL_GOODS': 'Всі товари',
    'Contact.TITLE': 'Зв\'яжіться з нами',
    'Catalog.INV_DOWNLOAD': 'Завантажити інвойс',
    'Catalog.INV_DOWNLOAD_TEXT': 'Ви можете встановити націнку, щоб включити її у вартість товарів у завантаженому файлі',
    'Catalog.INV_PERCENT': 'Націнка, %',
    'Catalog.INV': 'ІНВОЙС {inv}',
    'Catalog.EMPTY_HEADING': 'ТУТ НІЧОГО НЕМАЄ',
    'Catalog.EMPTY': 'Схоже, ми продали всі товари з цієї категорії.',
    'Catalog.EMPTY_TEXT': 'Слідкуйте за новими надходженнями, щоб бути в курсі останніх поставок. А поки що можете подивитися товари в інших категоріях, можливо, ви знайдете щось цікаве.',
    'CartConfirm.TOTAL': 'Всьго до сплати',
    'CartConfirm.INFO': 'Якщо у вас є якісь побажання щодо збірки або упаковки замовлених вами позицій, напишіть нам про це',
    'CartConfirm.RECIPIENT_CHOSE': 'Виберіть отримувача',
    'CartConfirm.RECIPIENT_EMPTY': 'Немає одержувачів',
    'CartConfirm.RECIPIENT_ADD': 'Додати одержувача',
    'CartConfirm.CONFIRM': 'ОФОРМИТИ ЗАМОВЛЕННЯ',
    'CartOrder.HEADING_INFO': 'ПІДТВЕРДЖЕННЯ ЗАМОВЛЕННЯ',
    'CartOrder.TEXT_INFO': 'Після замовлення вам обов\'язково передзвонить менеджер та уточнить усі деталі, необхідні для збирання замовлення.',
    'CartOrder.HEADING_DELEVERY': 'БЕЗКОШТОВНА ДОСТАВКА',
    'CartOrder.TEXT_DELEVERY': 'Ми повністю покриваємо витрати на доставку, ви сплачуєте лише вартість замовлення. Крім того, весь товар застрахований і, у разі будь-яких пошкоджень, компанія-перевізник відшкодовує повну вартість.',
    'CartOrder.EMPTY': 'Ваш кошик порожній!',
    'CartSuccess.ORDER_NUM': 'Номер вашого замовлення',
    'CartSuccess.ORDER_QTY': 'Кількість товарів',
    'CartSuccess.ORDER_SUM': 'Сума замовлення',
    'CartSuccess.INFO': 'Найближчим часом ми зв\'яжемося з вами для підтвердження замовлення та узгодження деталей доставки та оплати.',
    'CartSuccess.BOTTOM_HEADING': 'Зовсім скоро ми надішлемо ваше замовлення',
    'CartSuccess.BOTTOM_TEXT': 'Партнерські відносини з компанією Нова Пошта дають нам певні переваги, які дозволяють надсилати замовлення наших клієнтів у максимально стислий термін. Таким чином, ви отримаєте замовлення приблизно за 36 годин з моменту підтвердження менеджером або за 24 години, якщо замовлення зроблено у першій половині дня.',
    'CartSuccess.REVIEW_HEADING': 'Будь ласка, оцініть нашу роботу',
    'CartSuccess.REVIEW_TEXT_1': 'Щодня ми працюємо над тим, щоб зробити наш магазин та якість обслуговування ще кращою та зручнішою для вас.',
    'CartSuccess.REVIEW_TEXT_2': 'Якщо у вас виникли якісь побажання чи зауваження щодо роботи нашого магазину, будь ласка, напишіть нам про це за допомогою {link}, або будь-яким зручним для вас способом.',
    'CartSuccess.REVIEW_CONTACT_FORM': 'форми зворотного зв\'язку',
    'Contact.ADDRESS': 'Промтоварний ринок «7 кілометр»,{br}Овідіопольська дор., 2096,{br}Одеса, Одеська область,{br}65000',
    'Contact.NEW_ADDRESS_MAP': 'м. Одеса,{br}вул. Французький Бульвар, 66/2,{br}офіс 100',
    'ContactForm.HEADING': 'Надішліть нам повідомлення',
    'ContactForm.TITLE': 'Як з вами краще зв\'язатися?',
    'ContactForm.EMAIL': 'Поштою',
    'ContactForm.PHONE': 'По телефону',
    'ContactForm.NEW_HEADING': 'Ми чекаємо на ваш дзвінок або повідомлення',
    'ContactForm.NEW_PHONE': 'Телефон',
    'ContactForm.NEW_MESSANGER': 'Повідомлення',
    'ContactForm.NEW_ADDRESS': 'Адреса',
    'ContactForm.NEW_ADDRESS_INFO': 'м. Одеса, вул. Французький Бульвар, 66/2, офіс 100',
    'ContactForm.SEND': 'ВІДПРАВИТИ ПОВІДОМЛЕННЯ',
    'ChangeProfile.TITLE': 'Зміна персональних даних',
    'ChangeProfile.INFO': 'З метою безпеки зміна деяких даних неможлива. Якщо ви все ж таки хочете внести зміни, будь ласка, зв\'яжіться з вашим персональним менеджером',
    'Delivery.TITLE': 'Доставка і оплата',
    "Delivery.FREE_SHIPPING": "ДОСТАВКА {free} ЗАВЖДИ",
    "Delivery.FREE": "БЕЗКОШТОВНА",
    "Delivery.INDIVIDUAL_DISCOUNTS": "Також у нас на постійній основі діють {text}. {br}Зв’яжіться з менеджером, щоб дізнатися вашу.",
    "Delivery.INDIVIDUAL_DISCOUNT_TEXT": "індивідуальні знижки",
    "Delivery.MIN_ORDER": "Сума мінімального замовлення",
    "Delivery.PAYMENT_TITLE": "Оплата",
    "Delivery.PAYMENT_TEXT": "Ми завжди знайдемо спосіб, який підійде усім",
    "Delivery.SHIPPING_TITLE": "Доставляємо",
    "Delivery.SHIPPING_TEXT": "Нова Пошта, Укрпошта або будь-яка інша доставка, яка буде зручна саме для вас",
    'Delivery.BOTTOM_1_TITLE': 'Обробка замовлень',
    'Delivery.BOTTOM_1_TEXT_1': 'Після отримання замовлення, за потреби, наші менеджери зв\'яжуться з вами та підтвердять всю необхідну інформацію.',
    'Delivery.BOTTOM_1_TEXT_2': 'Зверніть увагу, що якщо замовлення було зроблено в першій половині дня, то він відправляється наступного дня, якщо в другій — через день.',
    'Delivery.BOTTOM_1_TEXT_3': 'Термін доставки по Україні від одного до двох днів після збирання замовлення.',
    'Delivery.BOTTOM_2_TITLE': 'Мінімальне замовлення',
    'Delivery.BOTTOM_2_TEXT_1': 'Ми оптова компанія, і щоб якісно обслуговувати наших клієнтів, ми маємо суму мінімального замовлення.',
    'Delivery.BOTTOM_2_TEXT_2': 'Це дозволяє нам максимально зосередитися на ефективності внутрішніх складських та логістичних процесів, щоб забезпечувати ту якість товарів та послуг, до яких звикли наші клієнти.',
    'Delivery.BOTTOM_2_TEXT_3': 'У місяці високого сезону (липень-серпень) сума мінімального замовлення може бути збільшена.',
    'Delivery.BOTTOM_3_TITLE': 'Страхування замовлень',
    'Delivery.BOTTOM_3_TEXT_1': 'Ми не можемо відповідати за пошкодження товару в процесі транспортування. Зі свого боку, ми упаковуємо товар таким чином, щоб мінімізувати можливі пошкодження, а також страхуємо весь товар на 100% вартості замовлення.',
    'Delivery.BOTTOM_3_TEXT_2': 'Якщо продукція у вашому замовленні була пошкоджена під час перевезення, будь ласка, зверніться з претензією до офісу компанії-перевізника — вони зобов\'язані відшкодувати вам повну вартість зіпсованого товару за ціною, зазначеною у накладній.',
    'Good.NOT_AVAILABLE': 'Немає в наявності',
    'Good.OUT': 'Недоступний',
    'ListProducts.HEADING': 'Ваше замовлення',
    'ListProducts.TOTAL': 'Всього на суму',
    'Login.ENTER': 'Вхід',
    'Login.ENTER_INFO': 'Для входу введіть номер телефону у форматі',
    'Login.PASSWORD': 'Введіть пароль',
    'Login.PASSWORD_INFO': 'Ми надіслали одноразовий пароль на номер {phone}',
    'Login.SMS': 'Не отримали SMS? {link1} або {link2}',
    'Login.RESEND': 'Надішліть його ще раз',
    'Login.NEW_NUMBER': 'введіть інший номер',
    'Login.SMS_WAIT': 'Очікуйте SMS на вказаний номер',
    'Login.ENTER_CLIENT': 'Вхід для клієнтів',
    'Login.REGISTER': 'Реєстрація',
    'Login.REGISTER_INFO': 'Будь ласка, введіть додаткові дані для реєстрації на сайті',
    'Login.REGISTER_BTN': 'Зареєструватись',
    'Maintenance.TITLE': 'Сайт зачинено на технічне обслуговування',
    'Maintenance.HEADING': 'ПРЯМО ЗАРАЗ МИ ДЕЩО ЗМІНЮЄМО, ТОМУ САЙТ НЕ ПРАЦЮЄ',
    'Maintenance.INFO_1': 'Ми намагаємося робити такі речі в години мінімального навантаження на {br} проект і нам дуже шкода, що ви потрапили до нас саме в цей момент.',
    'Maintenance.INFO_2': 'Будь ласка, зайдіть трохи пізніше або зв\'яжіться з одним з наших {br} менеджерів, якщо у вас щось термінове. Дякую за розуміння.',
    'Manager.TITLE': 'Ваш персональний менеджер',
    'Manager.ALT': 'Менеджер {name}',
    'Order.TITLE': 'Замовлення {orderId}',
    'Order.INVOICE': 'накладна',
    'Order.CART_ADD': 'ДОДАТИ ЦЕ ЗАМОВЛЕННЯ ДО КОШИКА',
    'OrdersTable.CONFIRM_DELETE': 'Ви впевнені, що бажаєте видалити замовлення?',
    'OrdersTable.DATE': 'Дата',
    'OrdersTable.NUMBER': 'Номер',
    'OrdersTable.SUM': 'Сума, {currency}',
    'OrdersTable.IN_ORDER': 'Що у замовленні',
    'OrdersTable.STATUS': 'Статус',
    'OrdersTable.SEE': 'переглянути',
    'OrdersTable.DOWNLOAD': 'скачати накладну',
    'OrdersTable.ADD_IN_CART': 'додати до кошика',
    'OrdersTable.EMPTY': 'Тут поки що немає замовлень :(',
    'Partners.HEADING': 'Ми так звикли до конкуренції, що, коли гідних суперників не залишилося, вирішили змагатися самі з собою',
    'Partners.TITLE': 'МИ ЕКСКЛЮЗИВНО ПРЕДСТАВЛЯЄМО БРЕНДИ CELLO І ELLOT В УКРАЇНІ',
    'Partners.OFICIAL': 'ОФІЦІЙНО ЗАРЕЄСТРУВАНІ У ТОРГІВЕЛЬНІЙ ПАЛАТІ КИТАЮ МАРКА І ЗНАК',
    'Profile.TITLE': 'Персональні дані',
    'Profile.USER_NAME': 'Ім\'я та прізвище',
    'Profile.USER_CITY': 'Місто',
    'Profile.USER_CITY_CHOSE': 'Виберіть місто',
    'Profile.USER_PHONE': 'Номери телефонів',
    'Profile.USER_EMAIL': 'Електронна пошта',
    'Profile.RECIPIENT': 'Дані одержувача',
    'Profile.RECIPIENT_FULLNAME': 'Ім\'я та прізвище одержувача',
    'Profile.RECIPIENT_NAME': 'Назва',
    'Profile.RECIPIENT_PHONE': 'Номер телефону',
    'Profile.RECIPIENT_PHONE_ADD': 'Телефон',
    'Profile.RECIPIENT_PAYMENT': 'Спосіб оплати',
    'Profile.RECIPIENT_PAYMENT_CHOSE': 'Оберіть спосіб оплати',
    'Profile.RECIPIENT_DELEVERY': 'Спосіб доставки',
    'Profile.RECIPIENT_DELEVERY_CHOSE': 'Виберіть спосіб доставки',
    'Profile.RECIPIENT_WAREHOUSE': 'Відділення',
    'Profile.RECIPIENT_WAREHOUSE_CARGO': 'Відділення перевізника',
    'Profile.RECIPIENT_WAREHOUSE_CHOSE': 'Виберіть відділення',
    'Profile.RECIPIENT_ADDRESS': 'Адреса доставки (вулиця, будинок, квартира)',
    'Profile.RECIPIENT_AREA': 'Область',
    'Profile.RECIPIENT_AREA_CHOSE': 'Виберіть область',
    'Profile.RECIPIENT_PAYMENT_ADD': 'Додавання одержувача',
    'Profile.RECIPIENT_EMPTY': 'У вас немає жодного отримувача. Будь ласка, {btn} його.',
    'Profile.RECIPIENT_EMPTY_ADD': 'додайте',
    'Product.END': 'Закінчився',
    'Product.OUT': 'Закінчується',
    'Product.CODE': 'Код товару: {productId}',
    'Product.INPACK': 'У ящику {inpack} штук',
    'Product.PRICE': 'Ціна за штуку',
    'Product.QTY': 'Кількість',
    'Product.IN_CART': 'У кошику',
    'Product.ADD_IN_CART': 'В кошик',
    'Product.RRP': 'Рекомендована націнка {rrp}%',
    'Product.DELEVERY': 'ШВИДКА ДОСТАВКА',
    'Product.INSURED': 'ТОВАР ЗАСТРАХОВАН',
    'Product.WARANTY': 'ГАРАНТІЯ ЯКОСТІ',
    'Product.RELATED': 'Рекомендуємо звернути увагу на ці товари',
    'Product.VIEWED': 'Переглянуті товари',
    'Product.IMG_CHOISE': 'Вибирайте за картинками',
    'Statistic.STATUS': 'Ваш поточний статус {status}',
    'Statistic.CONTACT': 'Зв\'яжіться з менеджером, щоб дізнатися, як змінити статус',
    'Statistic.CONTRACT': 'Договір',
    'Statistic.TURNOVER': 'Оборот {turnover}',
    'Statistic.TURNOVER_INFO': 'Це сума замовлень з початку року',
    'Statistic.DONE': 'Виконано',
    'Statistic.DONE_ORDERS': 'Замовлень{br}в {year} році',
    'Statistic.ORDERS': 'Замовлення за цей рік',
    'Statistic.LAST_ORDER': 'Ваше минуле замовлення ми доставили {time}',
    'Statistic.HOUR1': 'годину',
    'Statistic.HOUR2': 'години',
    'Statistic.HOUR3': 'годин',
  },
};