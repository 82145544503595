import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Delivery from './Delivery';

import { getSettings } from 'components/User/actions';

const mapStateToProps = state => ({
  userToken: state.user.token,
  settings: state.user.settings.items,
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    getSettings
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Delivery);
