export const SOLUTIONS = {
  ru: [
    {
      id: 1,
      title: 'Направления деятельности',
      icon: 'arrows-alt',
      html: `<h3>Направления</h3>
        <p>Наша компания уже долгое время занимает лидирующие позиции на рынке канцелярских товаров Украины. Канцтовары это то, что мы любим и с чем ассоциируемся у наших клиентов в первую очередь.</p>
        <p>Не так давно мы расширили ассортимент до хозяйственных товаров, игрушек для детей и сезонных товаров и уже становимся лучшими в этих сферах.</p>`
    },
    {
      id: 2,
      title: 'Собственное производство',
      icon: 'leaf',
      html: `<h3>Производство</h3>
        <p>В какой-то момент мы поняли, что можем сделать канцтовары лучше.</p>
        <p>Когда в одном месте встречаются вдохновленные профессионалы, многолетний опыт работы и непреодолимое желание сделать качественные и доступные продукты – случается что-то удивительное. Продукты, которыми мы действительно гордимся.</p>`
    },
    {
      id: 3,
      title: 'Широкая дистрибуция',
      icon: 'flag',
      html: `<h3>Дистрибуция</h3>
        <p>Каждый день мы обеспечиваем наших клиентов прекрасными товарами, сделанными с любовью к деталям и высокими требованиями к их качеству и цене. Секрет компании в том, что каждую секунду мы работаем чтобы быть лучшими в том, что мы делаем.</p>
        <p>Сотрудничая с нами, вы можете быть уверены в своем конкурентном преимуществе.</p>`
    },
    {
      id: 4,
      title: 'Качественная логистика',
      icon: 'paper-plane',
      html: `<h3>Логистика</h3>
        <p>Наша цель – сделать оптовые покупки в интернете удобными и доступными каждому, гарантировать потрясающий сервис и, главное, сохранить ваше время. Проведите его с теми, кто вам дорог, вместо утомительных поездок, телефонных разговоров и других рутинных вещей.</p>
        <p>Со своей стороны мы обеспечим своевременную доставку заказанных вами товаров.</p>`
    }
  ],
  uk: [
    {
      id: 1,
      title: 'Діяльність',
      icon: '/img/solutions/rocket.svg',
      html: `<h3>Діяльність</h3>
        <p>Наша компанія протягом багатьох років є лідером на ринку канцелярських товарів України.</p>
        <p>Завдяки нашому досвіду та ресурсам ми змогли включити в свій асортимент господарські та сезонні товари, і зараз впевнено йдемо до лідерства в цих сегментах.</p>`
    },
    {
      id: 2,
      title: 'Виробництво',
      icon: '/img/solutions/gears.svg',
      html: `<h3>Виробництво</h3>
        <p>Ми не просто продаємо канцтовари – ми створюємо їх самі.</p>
        <p>Об’єднавши натхненних професіоналів, багаторічний досвід та прагнення до інновацій, ми розробляємо якісні та доступні продукти, якими справді пишаємося.</p>`
    },
    {
      id: 3,
      title: 'Логістика',
      icon: '/img/solutions/delivery.svg',
      html: `<h3>Логістика</h3>
        <p>Наші логістичні можливості одні з найкращих на ринку завдяки власним спроможностям та партнерству з провідними гравцями.</p>
        <p>Ми робимо оптові покупки онлайн зручними та доступними та гарантуємо своєчасну доставку ваших замовлень.</p>`
    },
    {
      id: 4,
      title: 'Зв’язок з нами',
      icon: '/img/solutions/handshake.svg',
      html: `<h3>Зв’язок з менеджером</h3>
        <p>Зв’яжіться з нами, якщо прагнете співпрацювати з найкращими в галузі. </p>
        <p>Разом ми досягнемо високих результатів і забезпечимо ваш успіх на ринку.</p>
        <p><a href="tel:+380507779777" target="_blank"><span></span>+38 (050) 777-9-777</a><a href="https://t.me/sparta_manager" target="_blank"><span></span>Написати у Телеграм</a></p>`
    }
  ]
};