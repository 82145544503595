import React, { Component } from 'react';
import Manager from 'components/Cabinet/components/Manager';
import Breadcrumbs from 'components/Breadcrumbs';
import GoogleMapReact from 'google-map-react';
import { FormattedMessage } from 'react-intl';
import ContactForm from 'components/ContactForm';
import { ReactComponent as Bullet } from 'shared/img/bullet.svg';

class Contact extends Component {
  render() {
    const { style } = this.props;

    return(
      <div className={style.root}>
        <Breadcrumbs 
          breadcrumbs={[<FormattedMessage id="Contact.TITLE" />]} 
          element={<Manager />}
          classnames={{
            root: style.breadcrumbsRoot,
            breadcrumbs: style.breadcrumbs
          }}
        />
        <div className={style.container}>
          <div className={style.mapWrapper}>
            <div className={style.formContainer}>
              <div className={style.form}>
                <ContactForm />
              </div>
            </div>
            <div className={style.map}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
                defaultCenter={this.props.center}
                defaultZoom={this.props.zoom}
              >
                <div
                  className={style.marker}
                  lat={this.props.lat}
                  lng={this.props.lng}
                >
                  <div className={style.markerText}><strong>Color-It</strong><br/>
                    <FormattedMessage id="Contact.NEW_ADDRESS_MAP" values={{ br: <br/> }} />
                  </div>
                  <Bullet />
                </div>
              </GoogleMapReact>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Contact.defaultProps = {
  style: require('./Contact.module.scss'),
  center: {
    lat: 46.469,
    lng: 30.746
  },
  lat: 46.466703,
  lng: 30.752513,
  zoom: 16
}

export default Contact;