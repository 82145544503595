import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { getPriceItem } from 'utils/services';

import Loader from 'components/Loader';
import BalanceSms from './../BalanceSms';

import { BALANCE_STATUSES } from './../../constants';

class Balance extends Component {
  state = {
    currentPage: 1,
    totalItems: 0,
    lastPage: 0
  }

  componentDidMount(){
    const { userToken, fetchBalanceGrid, fetching, getUserBalance } = this.props;

    getUserBalance(userToken);
    if (!fetching){
      fetchBalanceGrid(userToken);
    }
  }

  componentDidUpdate(prevProps){
    const { balanceLogin, fetchBalanceGrid, userToken } = this.props;

    if (prevProps.balanceLogin && !balanceLogin){
      fetchBalanceGrid(userToken);
    }
  }

  static getDerivedStateFromProps(nextProps){
    const size = _.size(nextProps.balances);

    if (size){
      return {
        totalItems: size,
        lastPage: _.ceil(size/nextProps.perPage)
      }
    }else{
      return null;
    }
  }

  handleMore = () => {
    const { currentPage, lastPage } = this.state;

    let nextPage = _.min([_.sum([currentPage, 1]), lastPage]);

    if (nextPage !== currentPage){
      this.setState({
        currentPage: nextPage
      });
    }
  }

  render() {
    const { style, balances, fetching, userBalance, groupid, currency, perPage, access, currentLocale } = this.props;
    const { currentPage, lastPage } = this.state;

    return (
      <div className={style.root}>
        <div className={style.tableWrapper}>
          {access && !_.isEmpty(userBalance) && 
            <div className={style.balance}>
              <div className={style.balanceItem}>
                <div className={style.balanceName}><FormattedMessage id="Balance.BALANCE" />:</div>
                <div className={style.balanceVal}>{getPriceItem(userBalance.balance, 2, currency, currentLocale)}</div>
              </div>
            </div>
          }
          <div className={style.rootTable}>
            {access && !_.isEmpty(balances) 
              ? <div className={style.balaceTableWrapper}>
                  <table className={style.table}>
                    <thead>
                      <tr>
                        <th><FormattedMessage id="Balance.DATE" /></th>
                        <th><FormattedMessage id="Balance.DEBT" /></th>
                        <th><FormattedMessage id="Balance.CREDIT" /></th>
                        <th><FormattedMessage id="Balance.REMAINDER" /></th>
                        <th><FormattedMessage id="Balance.COMMENT" /></th>
                        <th><FormattedMessage id="Balance.INVOICE" /></th>
                      </tr>
                    </thead>
                    <TransitionGroup component="tbody">
                      {balances.slice(0, currentPage*perPage).map(balance => {
                        const comment = BALANCE_STATUSES[currentLocale][balance.comment] 
                                          ? BALANCE_STATUSES[currentLocale][balance.comment].replace('{id}', balance.id) 
                                          : balance.comment;

                        return (
                          <CSSTransition key={balance.id} timeout={{ enter: 300, exit: 200 }} classNames='fade' appear>
                            <tr key={balance.id}>
                              <td>
                                { moment(balance.added).isValid() ? moment(balance.added).format('lll') : <FormattedMessage id="App.NOT_SET" /> }
                              </td>
                              <td>
                                { getPriceItem(balance.debet, 2, currency, currentLocale) }
                              </td>
                              <td>
                                { getPriceItem(balance.credit, 2, currency, currentLocale) }
                              </td>
                              <td>
                                { getPriceItem(balance.total_balance, 2, currency, currentLocale) }
                              </td>
                              <td>
                                <div className={style.comment}>
                                  {['order', 'return'].includes(balance.comment) 
                                    ? <Link to={"/#order/"+balance.id} className={style.link}>
                                        { comment }
                                      </Link>
                                    : comment
                                  }
                                  {!_.isEmpty(balance.tooltip) && 
                                    <div className={style.infoIcon}>
                                      <FontAwesomeIcon icon='info' data-tip={balance.tooltip} />
                                      <ReactTooltip place='top' effect='float' className={style.tooltip} />
                                    </div>
                                  }
                                </div>
                              </td>
                              <td>
                                {balance.type === 'order' && 
                                  <a className={style.link} href={`${process.env.REACT_APP_COLORIT_IP}invoice${balance.id}_${groupid}.xls`} download>
                                    <FontAwesomeIcon icon='download' /> <FormattedMessage id="App.DOWNLOAD_SM" />
                                  </a>
                                }
                              </td>
                            </tr>
                          </CSSTransition>
                        )
                      })}
                    </TransitionGroup>
                  </table>
                  {currentPage < lastPage &&
                    <div className={style.moreWrapper}>
                      <button className={style.more} type="button" onClick={this.handleMore}>
                        <FormattedMessage id="App.SHOW_MORE" />
                      </button>  
                    </div>
                  }
                </div>
              : fetching 
                ? <div className={style.loader}>
                    <Loader />
                  </div>
                : access
                  ? <div className={style.emptyWrapper}>
                      <div className={style.empty}>
                        <FormattedMessage id="Balance.EMPTY" />
                      </div>
                    </div>
                  : <BalanceSms />
            }
          </div>
        </div>
      </div>
    )
  }
}

Balance.defaultProps = {
  style: require('./Balance.module.scss'),
  perPage: 100
};

Balance.propTypes = {
  balances: PropTypes.array.isRequired,
  fetchBalanceGrid: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  currentLocale: PropTypes.string.isRequired,
  decimals: PropTypes.number.isRequired,
  userToken: PropTypes.string.isRequired,
  userBalance: PropTypes.object.isRequired,
  getUserBalance: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  groupid: PropTypes.number.isRequired,
  access: PropTypes.bool.isRequired,
  balanceLogin: PropTypes.bool.isRequired
};

export default Balance;