import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import Breadcrumbs from 'components/Breadcrumbs';
import { Link } from 'react-router-dom';
import { getPriceItem } from 'utils/services';

import { CONSTANTS } from './constants';

class CartSuccess extends Component {
  componentDidMount() {
    this.props.resetCart();
  }

  componentWillUnmount() {
    this.props.resetCheckout();
  }

  render() {
    const { style, checkout, currency, currentLocale } = this.props;

    return (
      <div className={style.root}>
        <Breadcrumbs breadcrumbs = {CONSTANTS[currentLocale].breadcrumbs} />
        <div className={style.container}>
          <div className={style.infoBox}>
            <div className={style.info}>
              <table className={style.infoTable}>
                <tbody>
                  <tr>
                    <td><FormattedMessage id="CartSuccess.ORDER_NUM" />:</td>
                    <td>{ checkout.id }</td>
                  </tr>
                  <tr>
                    <td><FormattedMessage id="CartSuccess.ORDER_QTY" />:</td>
                    <td>{ checkout.count }</td>
                  </tr>
                  <tr>
                    <td><FormattedMessage id="CartSuccess.ORDER_SUM" />:</td>
                    <td>{ getPriceItem(checkout.amount, 2, currency, currentLocale) }</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={style.infoText}>
              <FontAwesomeIcon icon="phone-alt" />
              <p><FormattedMessage id="CartSuccess.INFO" /></p>
            </div>
          </div>
          <div className={style.bottom}>
            <div className={style.bottomLeft}>
              <div className={style.bottomLeftHeading}><FontAwesomeIcon icon="truck" flip="horizontal" />
                <FormattedMessage id="CartSuccess.BOTTOM_HEADING" />
              </div>
              <p>
                <FormattedMessage id="CartSuccess.BOTTOM_TEXT" />
              </p>
            </div>
            <div className={style.bottomRight}>
              <h4><FormattedMessage id="CartSuccess.REVIEW_HEADING" /></h4>
              <p><FormattedMessage id="CartSuccess.REVIEW_TEXT_1" /></p>
              <p><FormattedMessage id="CartSuccess.REVIEW_TEXT_2" values={{ link: <Link to="/contact"><FormattedMessage id="CartSuccess.REVIEW_CONTACT_FORM" /></Link> }}/></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

CartSuccess.defaultProps = {
  style: require('./CartSuccess.module.scss')
}

CartSuccess.propTypes = {
  checkout: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  currentLocale: PropTypes.string.isRequired,
  resetCheckout: PropTypes.func.isRequired,
  resetCart: PropTypes.func.isRequired
};

export default CartSuccess;