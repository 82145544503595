import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { FormattedMessage } from 'react-intl';

import LazyImage from 'components/LazyImage';
import Breadcrumbs from 'components/Breadcrumbs';
import Loader from 'components/Loader';
import ProductCard from 'components/ProductCard';
import Modal from 'components/Modal';
import { getPriceItem, getPrice, calcProductQty, getSalePercent, getProductSale } from 'utils/services';

import { ReactComponent as Barcode } from 'shared/img/barcode.svg';

class Product extends Component {
  state = {
    qty: 1,
    total: 0,
    isThumbOpen: false
  }

  componentDidMount(){
    const { userToken, fetchCategory } = this.props;

    this.getProduct();
    fetchCategory(userToken, 'viewed', null, '');
  }

  componentDidUpdate(prevProps) {
    const { product, decimals, match: { params } } = this.props;

    if (prevProps.match.params.productId !== params.productId) {
      this.getProduct();
    }

    if (
      (_.isEmpty(prevProps.product) && !_.isEmpty(product)) ||
      (!_.isEmpty(product) && !_.isEmpty(prevProps.product) && !_.isEqual(+product.id, +prevProps.product.id))
    ) {
      this.setState({ 
        qty: product.minnum,
        total: getPrice(_.multiply(product.minnum, product.price), decimals)
      });
    }
  }

  getProduct(){
    const { match: { params }, userToken, product, decimals } = this.props;

    if ((_.isEmpty(product) && params.productId) || (!_.isEmpty(product) && !_.isEqual(+product.id, +params.productId))){
      this.props.fetchProduct(userToken, params.productId);
    }else{
      this.setState({ 
        qty: product.minnum,
        total: getPrice(_.multiply(product.minnum, product.price), decimals)
      });
    }
  }

  handleChange = e => {
    const { qty } = this.state;
    
    if (e.target.validity.valid){
      this.setState({qty: e.target.value || qty})
    }
  }

  handleControl = type => () => {
    const { product, decimals } = this.props;

    const qty = calcProductQty(type, this.state.qty, product);

    this.setState({ 
      qty: qty,
      total: getPrice(_.multiply(qty, product.price), decimals)
    });
  }

  handleCart = () => {
    const { product, addCart, userToken } = this.props;
    const { qty } = this.state;

    addCart(product.id, qty, userToken);
  }

  handleThumb = () => {
    this.setState({ 
      isThumbOpen: !this.state.isThumbOpen
    });
  }

  getProducts = () => {
    const { product } = this.props;

    let res = [];

    for (let i = 0; i < 3; i++) {
      res.push(product);
    }
    return res;
  }

  render() {
    const { style, product, currency, decimals, cartProducts, viewed, currentLocale } = this.props;
    const { qty, total, isThumbOpen } = this.state;

    const inStock = product.instock && product.visible_im;
    const sale = getProductSale(product);

    return (
      <div className={style.root}>
        <Modal
          isOpen={isThumbOpen}
          onRequestClose={this.handleThumb}
          content={
            <div className={style.modal}>
              <LazyImage 
                image={{
                  src: product.imagebig,
                  alt: product.name,
                  lowResSrc: product.imagesmall
                }}
              />
            </div>
          }
        />
        <Breadcrumbs breadcrumbs={!_.isEmpty(product) ? product.breadcrumbs : []} back={'/catalog/'+product.catid} />
        {!_.isEmpty(product) 
          ? <div className={style.container}>
              <div className={style.productContainer}>
                <div className={style.product}>
                  <div onClick={this.handleThumb}>
                    {product.ends === 1 &&
                      <div className={style.ends}>
                        <span>{product.instock === 0
                          ? <FormattedMessage id="Product.END" />
                          : <FormattedMessage id="Product.OUT" />
                        }</span>
                      </div>
                    }
                    <LazyImage 
                      image={{
                        src: product.imagebig,
                        alt: product.name,
                        lowResSrc: product.imagesmall,
                        className: style.thumb,
                        wrapperClassName: style.thumbImage
                      }}
                    />
                  </div>
                  <div className={style.info}>
                    <div className={style.name}>{ product.name }</div>
                    <div className={style.codeBox}>
                      <div className={style.code}>
                        <FormattedMessage id="Product.CODE" values={{ productId: product.id }} />
                        <br />
                        {product.barcode && +product.barcode !== 0 && <div className={style.barcode}><Barcode /> {product.barcode}</div>}
                      </div>
                      <div className={style.inpack}>
                        <FormattedMessage id="Product.INPACK" values={{ inpack: product.inpack }} />
                      </div>
                    </div>
                    <div className={style.description}>{ product.abstract }</div>
                    <div className={style.controls}>
                      <div className={classNames(style.controlsRow, style.alignEnd)}>
                        <div className={style.priceBox}>
                          <div className={style.priceText}>
                            <FormattedMessage id="Product.PRICE" />:
                          </div>
                          <p className={style.priceCurrent}>{ getPriceItem(product.price, decimals, currency, currentLocale) }</p>
                        </div>
                        {sale > 0 ? (
                          <>
                            <p className={style.priceOriginal}>{ getPriceItem(product.gp, decimals, 'usd', currentLocale) }</p>
                            <div className={style.priceBadge}>{ getSalePercent(sale) }</div>
                          </>
                        ) : null}
                        <div className={style.qtyBox}>
                          <div className={style.qtyText}><FormattedMessage id="Product.QTY" />:</div>
                          {inStock
                            ? <div className={style.qty}>
                                <button type="button" className={classNames(style.qtyBtn, style.qtyBtnMinus)} onClick={this.handleControl('minus')}></button>
                                <input type="text" className={style.qtyInput} pattern="[0-9]*" onInput={this.handleChange} value={qty} onChange={this.handleChange} />
                                <button type="button" className={classNames(style.qtyBtn, style.qtyBtnPlus)} onClick={this.handleControl('plus')}></button>
                              </div>
                            : <div className={style.qty}>
                                <div className={classNames(style.qtyBtn, style.qtyBtnMinus)}></div>
                                <input type="text" className={style.qtyInput} pattern="[0-9]*" value={qty} disabled="disabled" />
                                <div className={classNames(style.qtyBtn, style.qtyBtnPlus)}></div>
                              </div>
                          }
                        </div>
                      </div>
                      <div className={style.controlsRow}>
                        <div className={style.total}>{ getPriceItem(total, 2, currency, currentLocale) }</div>
                        {inStock
                          ? _.findIndex(cartProducts, ['goodid', product.id]) !== -1
                              ? <Link className={classNames(style.cartBtn, style.cartBtnChecked)} to="/cart"><FontAwesomeIcon icon="check" />
                                  <FormattedMessage id="Product.IN_CART" />
                                </Link>
                              : <button className={style.cartBtn} type="button" onClick={this.handleCart}>
                                  <FontAwesomeIcon icon="shopping-cart" />
                                  <FormattedMessage id="Product.ADD_IN_CART" />
                                </button>
                          : null
                        }
                      </div>
                      <div className={style.boxRrp}><FormattedMessage id="Product.RRP" values={{ rrp: product.rrp }} /></div>
                    </div>
                    <div className={style.iconItems}>
                      <div className={style.iconItem}>
                        <FormattedMessage id="Product.DELEVERY" />
                        <FontAwesomeIcon icon="truck" />
                      </div>
                      <div className={style.iconItem}>
                        <FormattedMessage id="Product.INSURED" />
                        <FontAwesomeIcon icon="shield-alt" />
                      </div>
                      <div className={style.iconItem}>
                        <FormattedMessage id="Product.WARANTY" />
                        <FontAwesomeIcon icon="thumbs-up" />
                      </div>
                    </div>
                  </div>
                </div>
                {product.features.length 
                  ? <div className={style.related}>
                      <div className={style.relatedTitle}>
                        <FormattedMessage id="Product.RELATED" />
                      </div>
                      <div className={style.relatedProducts}>
                        {_.map(product.features, 
                          (product, key) => <ProductCard key={`${key}_${product.id}`} product={product} />
                        )}
                      </div>
                    </div>
                  : null
                }
                {!_.isEmpty(viewed) && 
                  <div className={style.related}>
                    <div className={style.relatedTitle}>
                      <FormattedMessage id="Product.VIEWED" />
                    </div>
                    <div className={style.relatedProducts}>
                      {_.map(_.slice(viewed.data, 0, 6), 
                        (product, key) => <ProductCard key={`${key}_${product.id}`} product={product} />
                      )}
                    </div>
                  </div>
                }
              </div>
            </div>
          : <div className={style.loader}>
              <Loader />
            </div>
        }
      </div>
    )
  }
}

Product.defaultProps = {
  style: require('./Product.module.scss')
};

Product.propTypes = {
  product: PropTypes.object.isRequired,
  currentLocale: PropTypes.string.isRequired,
  userToken: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  decimals: PropTypes.number.isRequired,
  addCart: PropTypes.func.isRequired,
  fetchProduct: PropTypes.func.isRequired,
  cartProducts: PropTypes.array.isRequired,
  viewed: PropTypes.object.isRequired,
  fetchCategory: PropTypes.func.isRequired
};

export default Product;