
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { getPriceItem, getPrice, calcProductQty, getSalePercent, getProductSale } from 'utils/services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';

import LazyImage from 'components/LazyImage';

import { ReactComponent as Barcode } from 'shared/img/barcode.svg';

class ProductCard extends Component {
  state = {
    qty: 1,
    total: 0,
  }

  componentDidMount() {
    const { product, decimals } = this.props;

    this.setState({ 
      qty: product.minnum,
      total: getPrice(_.multiply(product.minnum, product.price), decimals)
    });
  };
  
  handleChange = e => {
    const { qty } = this.state;

    if (e.target.validity.valid){
      this.setState({qty: e.target.value || qty});
    };
  };
  
  handleBlur = e => {
    const { product, decimals } = this.props;
    
    if (e.target.validity.valid){
      this.setState({
        qty: calcProductQty('change', e.target.value, product),
        total: getPrice(_.multiply(e.target.value, product.price), decimals)
      });
    };
  };

  handleControl = type => () => {
    const { product, decimals } = this.props;

    const qty = calcProductQty(type, this.state.qty, product);

    this.setState({ 
      qty: qty,
      total: getPrice(_.multiply(qty, product.price), decimals)
    });
  }

  handleCart = productId => () => {
    const { addCart, userToken } = this.props;
    const { qty } = this.state;

    addCart(productId, qty, userToken);
  }

  render() {
    const { style, product, removeBtn, handleRemove, decimals, currency, cartProducts, className, linkOnClick, currentLocale } = this.props;
    const { qty, total } = this.state;

    const productLink = product.is_mix === 1 ? '/mix/'+product.id : '/product/'+product.id;
    const sale = getProductSale(product);

    return (
      <div className={classNames(style.product, style[className])}>
        {removeBtn && handleRemove && 
          <button className={style.removeBtn} onClick={handleRemove}>
            <FontAwesomeIcon icon="times" />
          </button>
        }
        {product.is_mix === 1 && 
          <div className={style.mix}>
            <span>
              <FormattedMessage id="Product.IMG_CHOISE" />
            </span>
          </div>
        }
        {product.ends === 1 &&
          <div className={style.ends}>
            <span>{product.instock === 0 
              ? <FormattedMessage id="Product.END" />
              : <FormattedMessage id="Product.OUT" />
            }</span>
          </div>
        }
        <Link className={style.imgWrapper} to={productLink} onClick={linkOnClick}>
          <LazyImage
            image={{
              wrapperClassName: style.img,
              src: product.image,
              alt: product.name,
              lowResSrc: product.imagesmall
            }}
          />
        </Link>
        <div className={style.info}>
          <Link className={style.name} to={productLink} onClick={linkOnClick}>{product.name}</Link>
          <div className={style.infoRow}>
            <div className={style.infoRowItem}>
              <div className={style.code}>
                <FormattedMessage id="Product.CODE" values={{ productId: product.id }} />
              </div>
            </div>
            <div className={style.infoRowItem}>
              {product.barcode && +product.barcode !== 0 && <div className={style.barcode}><Barcode /> {product.barcode}</div>}
            </div>
          </div>
        </div>
        <div className={style.controls}>
          <div className={style.priceBox}>
            <div className={style.priceText}><FormattedMessage id="Product.PRICE" />:</div>
              <p className={style.priceCurrent}>{ getPriceItem(product.price, decimals, currency, currentLocale) }</p>
            </div>
          {sale > 0 ? (
            <>
              <p className={style.priceOriginal}>{ getPriceItem(product.gp, decimals, 'usd', currentLocale) }</p>
              <div className={style.priceBadge}>{ getSalePercent(sale) }</div>
            </>
          ) : null}
          <div className={style.qtyBox} ref={node => { this[`boxQty_${product.id}`] = node }}>
            <div className={style.qtyText}><FormattedMessage id="Product.QTY" />:</div>
            <div className={style.qty}>
              <button type="button" className={classNames(style.qtyBtn, style.qtyBtnMinus)} onClick={this.handleControl('minus')}></button>
              <input type="text" className={style.qtyInput} pattern="[0-9]*" value={qty} onChange={this.handleChange} onBlur={this.handleBlur} />
              <button type="button" className={classNames(style.qtyBtn, style.qtyBtnPlus)} onClick={this.handleControl('plus')}></button>
            </div>
          </div>
        </div>
        <div className={style.bottom}>
          <div className={style.total}>{ getPriceItem(total, 2, currency, currentLocale) }</div>
          {_.findIndex(cartProducts, ['goodid', product.id]) !== -1
            ? <Link className={classNames(style.cartBtn, style.cartBtnChecked)} to="/cart"><FontAwesomeIcon icon="check" /><FormattedMessage id="Product.IN_CART" /></Link>
            : product.ends === 1 && product.instock === 0 
              ? <button className={style.cartBtn} type="button" disabled="disabled"><FontAwesomeIcon icon="shopping-cart" /><FormattedMessage id="Product.ADD_IN_CART" /></button>
              : <button className={style.cartBtn} type="button" onClick={this.handleCart(product.id)}><FontAwesomeIcon icon="shopping-cart" /><FormattedMessage id="Product.ADD_IN_CART" /></button>
          }
        </div>
      </div>
    )
  }
}

ProductCard.defaultProps = {
  style: require('./ProductCard.module.scss'),
  className: ''
};

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  currentLocale: PropTypes.string.isRequired,
  decimals: PropTypes.number.isRequired,
  addCart: PropTypes.func.isRequired,
  cartProducts: PropTypes.array.isRequired,
  className: PropTypes.string,
  linkOnClick: PropTypes.func,
};

export default ProductCard;