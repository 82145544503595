import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import Logo from 'shared/img/logo_big.png';

import { SOLUTIONS } from './../../constants';

class LoginSolutions extends Component {
  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
  }

  state = {
    solutionId: null
  }

  handleNav = solutionId => () => {
    if (this.state.solutionId !== solutionId){
      this.setState({
        solutionId: solutionId
      });
    }

    this.scrollRef.current.scrollIntoView();
  }

  handleLogo = e => {
    if (this.state.solutionId){
      e.preventDefault();
      this.setState({
        solutionId: null
      });
    }
  }

  render() {
    const { style, currentLocale } = this.props;
    const { solutionId } = this.state;

    const timeout = { enter: 300, exit: 200 };

    return (
      <div className={classNames(
        style.root,
        {[style.opened]: solutionId}
      )} ref={this.scrollRef}>
        <Link 
          className={classNames(
            style.logoBtn,
            {[style.opened]: solutionId}
          )} 
          to='/' 
          onClick={this.handleLogo}
        >
          <img src={Logo} alt='Sparta лого' className={style.logoImg} />
        </Link>
        {solutionId && 
          <TransitionGroup className={style.htmlWrapper}>
            <CSSTransition key={solutionId} timeout={timeout} classNames='fade' appear>
              <div className={style.html} dangerouslySetInnerHTML={{__html: _.find(SOLUTIONS[currentLocale], ['id', solutionId]).html}} />
            </CSSTransition>
          </TransitionGroup>
        }
        <div className={style.nav}>
          {SOLUTIONS[currentLocale].map(solution => 
            <div key={solution.id} className={classNames(style.navItem, {[style.active]: solution.id===solutionId})} onClick={this.handleNav(solution.id)}>
              <div className={style.navIcon}>
                <img src={solution.icon} className={style.icon} alt="" />
              </div>
              {solution.title}
            </div>
          )}
        </div>
        {/* <div className={style.arrow}></div> */}
        <Link className={style.btn} to='/login-form'><FormattedMessage id="Login.ENTER_CLIENT" /></Link>
      </div>
    )
  }
}

LoginSolutions.defaultProps = {
  style: require('./LoginSolutions.module.scss')
}

LoginSolutions.propTypes = {
  currentLocale: PropTypes.string.isRequired, 
}

export default LoginSolutions;