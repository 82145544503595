export const SOLUTIONS = {
  ru: [
    {
      id: 1,
      title: 'Направления деятельности',
      icon: 'arrows-alt',
      html: `<h3>Направления</h3>
        <p>Наша компания уже долгое время занимает лидирующие позиции на рынке канцелярских товаров Украины. Канцтовары это то, что мы любим и с чем ассоциируемся у наших клиентов в первую очередь.</p>
        <p>Не так давно мы расширили ассортимент до хозяйственных товаров, игрушек для детей и сезонных товаров и уже становимся лучшими в этих сферах.</p>`
    },
    {
      id: 2,
      title: 'Собственное производство',
      icon: 'leaf',
      html: `<h3>Производство</h3>
        <p>В какой-то момент мы поняли, что можем сделать канцтовары лучше.</p>
        <p>Когда в одном месте встречаются вдохновленные профессионалы, многолетний опыт работы и непреодолимое желание сделать качественные и доступные продукты – случается что-то удивительное. Продукты, которыми мы действительно гордимся.</p>`
    },
    {
      id: 3,
      title: 'Широкая дистрибуция',
      icon: 'flag',
      html: `<h3>Дистрибуция</h3>
        <p>Каждый день мы обеспечиваем наших клиентов прекрасными товарами, сделанными с любовью к деталям и высокими требованиями к их качеству и цене. Секрет компании в том, что каждую секунду мы работаем чтобы быть лучшими в том, что мы делаем.</p>
        <p>Сотрудничая с нами, вы можете быть уверены в своем конкурентном преимуществе.</p>`
    },
    {
      id: 4,
      title: 'Качественная логистика',
      icon: 'paper-plane',
      html: `<h3>Логистика</h3>
        <p>Наша цель – сделать оптовые покупки в интернете удобными и доступными каждому, гарантировать потрясающий сервис и, главное, сохранить ваше время. Проведите его с теми, кто вам дорог, вместо утомительных поездок, телефонных разговоров и других рутинных вещей.</p>
        <p>Со своей стороны мы обеспечим своевременную доставку заказанных вами товаров.</p>`
    }
  ],
  uk: [
    {
      id: 1,
      title: 'Діяльність',
      icon: '/img/solutions/rocket.svg',
      html: `<h3>Діяльність</h3>
        <p>Наша компанія протягом багатьох років є лідером на ринку канцелярських товарів України.</p>
        <p>Завдяки нашому досвіду та ресурсам ми змогли включити в свій асортимент господарські та сезонні товари, і зараз впевнено йдемо до лідерства в цих сегментах.</p>`
    },
    {
      id: 2,
      title: 'Виробництво',
      icon: '/img/solutions/gears.svg',
      html: `<h3>Виробництво</h3>
        <p>Ми не просто продаємо канцтовари – ми створюємо їх самі.</p>
        <p>Об’єднавши натхненних професіоналів, багаторічний досвід та прагнення до інновацій, ми розробляємо якісні та доступні продукти, якими справді пишаємося.</p>`
    },
    {
      id: 3,
      title: 'Логістика',
      icon: '/img/solutions/delivery.svg',
      html: `<h3>Логістика</h3>
        <p>Наші логістичні можливості одні з найкращих на ринку завдяки власним спроможностям та партнерству з провідними гравцями.</p>
        <p>Ми робимо оптові покупки онлайн зручними та доступними та гарантуємо своєчасну доставку ваших замовлень.</p>`
    },
    {
      id: 4,
      title: 'Зв’язок з нами',
      icon: '/img/solutions/handshake.svg',
      html: `<h3>Зв’язок з менеджером</h3>
        <p>Зв’яжіться з нами, якщо прагнете співпрацювати з найкращими в галузі. </p>
        <p>Разом ми досягнемо високих результатів і забезпечимо ваш успіх на ринку.</p>
        <p><span><a href="tel:+380507779777" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="18px" height="18px" viewBox="0 0 256 256" xml:space="preserve"><defs></defs><g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"><path d="M 38.789 51.211 l 10.876 10.876 c 0.974 0.974 2.471 1.194 3.684 0.543 l 13.034 -6.997 c 0.964 -0.518 2.129 -0.493 3.07 0.066 l 19.017 11.285 c 1.357 0.805 1.903 2.489 1.268 3.933 c -1.625 3.698 -4.583 10.476 -5.758 13.473 c -0.247 0.631 -0.615 1.209 -1.127 1.652 c -12.674 10.986 -37.89 -2.4 -57.191 -21.701 C 6.358 45.039 -7.028 19.823 3.958 7.149 c 0.444 -0.512 1.022 -0.88 1.652 -1.127 c 2.996 -1.175 9.775 -4.133 13.473 -5.758 c 1.444 -0.635 3.128 -0.089 3.933 1.268 l 11.285 19.017 c 0.558 0.941 0.583 2.106 0.066 3.07 L 27.37 36.651 c -0.651 1.213 -0.431 2.71 0.543 3.684 C 27.913 40.335 38.789 51.211 38.789 51.211 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #c4c4c4; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" /></g></svg>+38 (050) 777-9-777</a></span><span><?xml version="1.0" encoding="utf-8"?><svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 1C2.34315 1 1 2.34315 1 4V15C1 16.6569 2.34315 18 4 18H6V22C6 22.388 6.22446 22.741 6.57584 22.9056C6.92723 23.0702 7.3421 23.0166 7.64018 22.7682L13.362 18H20C21.6569 18 23 16.6569 23 15V4C23 2.34315 21.6569 1 20 1H4Z" fill="#c4c4c4"/></svg> Написати у <a href="https://t.me/sparta_manager" target="_blank">Телеграм</a> або <a href="viber://chat?number=+380507779777" target="_blank">Вайбер</a></span></p>`
    }
  ]
};