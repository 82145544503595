import _ from 'lodash';

const truncate = (n, precision) => {
  const f = Math.pow(10, Math.max(precision | 0, 0)) * 10;
  return Math.trunc(n * f) / f;
};

export const getPriceItem = (price, decimals, isoCode, locale) => {
  return `${getCurrency(isoCode, locale)}${getPrice(price, decimals)}`;
}

export const getPrice = (price, decimals) => {
  return truncate(_.round(price, decimals), decimals);
}

export const getRealPrice = (price, sale) => (price * (1 + sale/100));

export const relDiff = (a, b) => (100 * Math.abs((a - b) / ((a + b)/2)));

export const getOriginalPriceBySale = (price, sale, decimals) => {
  if (!sale) {
    return price;
  }

  const realPrice = getRealPrice(price, sale);

  return _.round(realPrice, decimals).toFixed(decimals);
}

export const getSalePercent = (sale) => {
  if (sale) {
    return `${sale}%`;
  }
}

export const getCurrency = (isoCode, locale) => {
  const currencies = {
    ru: {
      'usd': '$',
      'uah': '₴',
    },
    uk: {
      'usd': '$',
      'uah': '₴',
    },
  };

  return currencies[locale][isoCode] || null;
}

export const preparePhone = (phone) => {
  return phone.replace(/^\+38/gm, '').replace(/[^\d]/g, '');
}

export const calcProductQty = (type, qty, product) => {
  if (type === 'plus'){
    qty = (+qty%+product.step !== 0) 
      ? _.multiply(_.ceil(_.divide(+qty, +product.step)), +product.step) 
      : _.sum([+qty, +product.step]);
  }else if(type === 'minus'){
    qty = (+qty%+product.step !== 0) 
      ? _.max([_.multiply(_.floor(_.divide(+qty, +product.step)), +product.step), +product.step])
      : _.max([_.subtract(+qty, +product.step), +product.step]);
  }else if (type === 'change'){
    let residue = +qty%+product.step;
    residue = residue === 0 ? 0 : +product.step-residue;
    qty = _.max([+qty+residue, +product.step]);
  }

  return qty;
}

export const decOfNum = (number, titles) => {
  var decCache = [],
      decCases = [2, 0, 1, 1, 1, 2];

  if(!decCache[number]) decCache[number] = number % 100 > 4 && number % 100 < 20 ? 2 : decCases[Math.min(number % 10, 5)];
  return titles[decCache[number]];
}

export const getProductSale = (product) => {
  if (+product.gp && +product.gp > +product.price) {
    return Math.round(((+product.gp - +product.price)/+product.price)*100);
  }

  return 0;
}