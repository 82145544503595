import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { getPriceItem } from 'utils/services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class CartModal extends Component {
  componentDidMount() {
    const { userToken, cart } = this.props;

    if (_.isEmpty(cart)){
      this.props.fetchCart(userToken);
    }
  }

  handleCart = () => {
    this.props.categoryOpen && this.props.toggleCategories();
  }

  render() {
    const { style, modificator, cart, currency, currentLocale } = this.props;

    return (
      <div className={style.root}>
        <Link className={classNames(style.btnCart, style[modificator])} to={'/cart'} onClick={this.handleCart}>
          <FontAwesomeIcon icon="shopping-cart" />
          {!_.isEmpty(cart)
            ? getPriceItem(cart.amount, 2, currency, currentLocale)
            : getPriceItem(0.00, 2, currency, currentLocale)
          }
        </Link>
      </div>
    )
  }
}

CartModal.defaultProps = {
  style: require('./CartModal.module.scss')
}

CartModal.propTypes = {
  modificator: PropTypes.string,
  cart: PropTypes.object.isRequired,
  currency: PropTypes.string,
  userToken: PropTypes.string.isRequired,
  currentLocale: PropTypes.string.isRequired,
  categoryOpen: PropTypes.bool.isRequired,
  toggleCategories: PropTypes.func.isRequired
};

export default CartModal;